import React from "react";
import { IconAdd } from "../common/components/icons";
import { OnboardingActionType } from "../constant/ActionTypes";

// Neutral/100 - #C1C4CE
// Primary/100 - #6C8B94
// Warning/100 - #FFDB86
// Info/100 - #7DCEFC
// Danger/100 - #F58787
// Success/100 - #B1DB88

const defaultColor       = "hsl(226, 12%, 78%)";
const primaryColor       = "hsl(194, 16%, 50%)"; // pri green
const successColor       = "hsl(90, 54%, 70%)"; // success green
const pendingColor       = "hsl(42, 100%, 76%)"; // orange
const inprogressColor    = "hsl(202, 95%, 74%)"; // blue
const rejectedColor      = "hsl(0, 85%, 75%)"; // red

const ONBOARDING_STATUS = Object.freeze({
  0: {
    status: 0,
    icon: IconAdd,
    label: null,
    color: defaultColor,
    action: {
      icon: IconAdd,
      type: OnboardingActionType.ADD_TO_SHORTLIST,
      label: "Shortlist",
    },
  },
  1: {
    status: 1,
    icon: IconAdd,
    label: "Short Listed",
    color: primaryColor,
    action: {
      icon: IconAdd,
      type: OnboardingActionType.ADD_TO_PROJECT,
      label: "Add to Project",
    },
  },
  2: {
    status: 2,
    icon: IconAdd,
    label: "Project Added",
    color: primaryColor,
    action: {
      icon: IconAdd,
      type: OnboardingActionType.RE_INVITE,
      label: "Re Invite",
    },
  },
  3: {
    status: 3,
    icon: IconAdd,
    label: "Invitation Sent",
    color: pendingColor,
    action: {
      icon: IconAdd,
      type: null,
      label: null,
    },
  },
  4: {
    status: 4,
    icon: IconAdd,
    label: "Invitation Accepted",
    color: inprogressColor,
    action: {
      icon: null,
      type: null,
      label: null,
    },
  },
  5: {
    status: 5,
    icon: IconAdd,
    label: "Invitation Rejected",
    color: rejectedColor,
    action: {
      icon: IconAdd,
      type: OnboardingActionType.RE_INVITE,
      label: "Re Invite",
    },
  },
  6: {
    status: 6,
    icon: IconAdd,
    label: "Contract Requested",
    color: inprogressColor,
    action: {
      icon: IconAdd,
      type: OnboardingActionType.GENERATE_CONTRACT,
      label: {
        hr: "Generate Contract",
        onboarding: null,
      },
    },
  },
  7: {
    status: 7,
    icon: IconAdd,
    label: "Contract Change Requested",
    color: inprogressColor,
    action: {
      icon: IconAdd,
      type: OnboardingActionType.VIEW_CONTRACT,
      label: {
        hr: "View or Generate Contract",
        onboarding: "View Contract",
      },
    },
  },
  8: {
    status: 8,
    icon: IconAdd,
    label: "Contract Generated",
    color: inprogressColor,
    action: {
      icon: IconAdd,
      type: {
        hr: OnboardingActionType.VIEW_CONTRACT,
        onboarding: null,
      },
      label: {
        hr: "View Contract",
      },
    },
  },
  9: {
    status: 9,
    icon: IconAdd,
    label: "Contract Sent",
    color: inprogressColor,
    action: {
      icon: IconAdd,
      type: OnboardingActionType.VIEW_CONTRACT,
      label: {
        hr: "View Contract",
        onboarding: "View Contract",
      },
    },
  },
  10: {
    status: 10,
    icon: IconAdd,
    label: "Contract Signed",
    color: inprogressColor,
    action: {
      icon: IconAdd,
      type: {
        hr: OnboardingActionType.ONBOARD,
        onboarding: null,
      },
      label: {
        hr: "Onboard",
        onboarding: null,
      },
    },
  },
  11: {
    status: 11,
    icon: IconAdd,
    label: "Contract Accepted",
    color: inprogressColor,
    action: {
      icon: IconAdd,
      type: {
        hr: OnboardingActionType.ONBOARD,
        onboarding: OnboardingActionType.SIGN_CONTRACT,
      },
      label: {
        hr: "Onboard",
        onboarding: "Sign Contract",
      },
    },
  },
  12: {
    status: 12,
    icon: IconAdd,
    label: "Contract Rejected",
    color: rejectedColor,
    action: {
      icon: IconAdd,
      type: OnboardingActionType.RE_INVITE,
      label: "Re Invite",
    },
  },
  13: {
    status: 13,
    icon: IconAdd,
    label: "Contract Executed",
    color: successColor,
    action: {
      icon: IconAdd,
      type: {
        hr: OnboardingActionType.ONBOARD,
        onboarding: null,
      },
      label: {
        hr: "Onboard",
        onboarding: null,
      },
    },
  },
  14: {
    status: 14,
    icon: IconAdd,
    label: "Contract Rejected Is Ack",
    color: rejectedColor,
    action: {
      icon: IconAdd,
      type: OnboardingActionType,
      label: "None",
    },
  },
  15: {
    status: 15,
    icon: IconAdd,
    label: "Onboarded",
    color: successColor,
    action: {
      icon: IconAdd,
      type: null,
      label: null,
    },
  },
  16: {
    status: 16,
    icon: IconAdd,
    label: "Contract in progress",
    color: inprogressColor,
    action: {
      icon: IconAdd,
      type: OnboardingActionType.VIEW_CONTRACT,
      label: {
        hr: "View Contract",
        onboarding: null,
      },
    },
  },
  17: {
    status: 17,
    icon: IconAdd,
    label: "Contract Accepted By HR",
    color: inprogressColor,
    action: {
      icon: IconAdd,
      type: OnboardingActionType.VIEW_CONTRACT,
      label: {
        hr: "View Contract",
        onboarding: "View Contract",
      },
    },
  },
  18: {
    status: 18,
    icon: IconAdd,
    label: "Contract Approved",
    color: inprogressColor,
    action: {
      icon: IconAdd,
      type: OnboardingActionType.VIEW_CONTRACT,
      label: {
        hr: null,
        onboarding: "View Contract",
      },
    },
  },
  19: {
    status: 19,
    icon: IconAdd,
    label: "Requested Contract by member",
    color: inprogressColor,
    action: {
      icon: IconAdd,
      type: OnboardingActionType.CONTRACT_REQUESTED,
      label: "Contract",
    },
  },
  20: {
    status: 20,
    icon: IconAdd,
    label: "Contract Declined by member",
    color: rejectedColor,
    action: {
      icon: null,
      type: null,
      label: null,
    },
  },
});

const useSystemStatus = () => {
  /**
   * Onboarding status
   */
  const getOnboardingStatus = React.useCallback((val) => {
    return ONBOARDING_STATUS[val] || null;
  }, []);

  return {
    getOnboardingStatus,
  };
};

export default useSystemStatus;
