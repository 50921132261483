import { generatePath } from "react-router-dom";
import { ROUTE_OPTIONS } from "../constant/route-options";

const viewContractPathParams = {
  userId: 0,
  contractId: 0,
  contractRequestId: 0,
  source: "",
};

const useGeneratePath = () => {
  const genViewContractPath = (params = viewContractPathParams) => {
    const payload = {
      ...viewContractPathParams,
      ...params,
    };

    const path = generatePath(
      ROUTE_OPTIONS.projects.children["view-contract"].path,
      payload
    );

    return `${ROUTE_OPTIONS.projects.path}/${path}`;
  };

  const genBriefPath = (projectId) => {
    const path = generatePath(
      ROUTE_OPTIONS.projects.children["project-brief"].path,
      { id: projectId }
    );

    return `${ROUTE_OPTIONS.projects.path}/${path}`;
  };

  return {
    genViewContractPath,
    genBriefPath,
  };
};

export default useGeneratePath;
