import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import * as Styled from "./styled";
import FieldGroup from "../../../../../../../../../../../common/components/Form/FieldGroup";
import Button from "../../../../../../../../../../../common/components/Buttons/UIButton";
import { useActivityBlock } from "../../../../context/ActivityBlockContext";
import SelectField from "../../../../../../../../../../../common/components/Form/SelectField";
import { ColorButton, ItemRow } from "../../../../styled/common";

const validationSchema = Yup.object().shape({
  stage: Yup.string().required("Stage is required"),
  // colorCode: Yup.string().required("Color Code is required"),
  code: Yup.string()
    .required("Code is required")
    .max(5, "Code must be less than 5 characters"),
  name: Yup.string().required("Name is required"),
});

const initialValues = {
  stage: "",
  colorCode: "",
  code: "",
  name: "",
};

function Form({ onSubmit, formData }) {
  const { milestoneModalData, MilestoneModal } = useActivityBlock();

  const isEditMode = milestoneModalData?.payload?.type === "EDIT";

  const stages = [{ label: "Pre production", value: 1 }];

  /**
   *
   */
  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values, actions) => {
      const val = {
        ...values,
      };

      onSubmit(val, actions);
    },
  });

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  const itemRowError = React.useMemo(() => {
    if (!touched.code && !touched.name) {
      return;
    }

    return errors.code || errors.name;
  }, [errors, touched]);

  return (
    <Styled.Form onSubmit={handleSubmit}>
      <FieldGroup
        label="Production Stage"
        error={touched.stage && errors.stage}
      >
        <SelectField
          placeholder="Select"
          options={stages}
          value={stages.find((d) => d?.value === values.stage)}
          onChange={(v) => {
            setFieldValue("stage", v?.value);
          }}
        />
      </FieldGroup>

      <FieldGroup error={itemRowError}>
        <ItemRow className="input-row">
          <div className="inputs">
            <ColorButton />

            <div className="split"></div>

            <input
              name="code"
              className="code-input"
              type="text"
              placeholder="Code"
              value={values.code}
              onChange={handleChange}
            />

            <div className="split"></div>

            <input
              name="name"
              type="text"
              placeholder="Milestone Name"
              value={values.name}
              onChange={handleChange}
            />
          </div>
        </ItemRow>
      </FieldGroup>

      <FieldGroup className="actions">
        <Button
          type="button"
          variant={Button.Variant.Danger}
          size={Button.Sizes.Small}
          className="cancel__btn"
          onClick={() => {
            MilestoneModal.close();
          }}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          variant={Button.Variant.Primary}
          size={Button.Sizes.Small}
        >
          {isEditMode ? "Save" : "Add"}
        </Button>
      </FieldGroup>
    </Styled.Form>
  );
}

export default Form;
