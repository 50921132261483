import React from "react";
import * as Yup from "yup";
import { useField, useFormik } from "formik";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  ColorButton,
  ItemRow,
  ItemRowWrapper,
} from "../../../../styled/common";
import IconButton from "../../../../../../../../../../../common/components/Buttons/IconButton";
import IconImage from "../../../../../../../../../../../common/components/icons/Line/image";
import IconDuplicate from "../../../../../../../../../../../common/components/icons/Line/Duplicate/01";
import IconDelete from "../../../../../../../../../../../common/components/icons/Line/Delete/01";
import IconDrag from "../../../../../../../../../../../common/components/icons/Fill/drag";
import IconAdd1 from "../../../../../../../../../../../common/components/icons/Line/add-1";
import CustomColorPicker from "../../../../../../../../../../../common/components/Form/CustomColorPicker";
import IconPicker from "../../../../../../../../../../../common/components/Form/IconPicker";
// import IconEdit from "../../../../../../../../../../../common/components/icons/Line/Edit/01";

const validationSchema = Yup.object().shape({
  activities: Yup.array().of(
    Yup.object().shape({
      color: Yup.string(),
      code: Yup.string(),
      name: Yup.string(),
      icon: Yup.string(),
    })
  ),
});

function ActivityList({ value = [], onChange }) {
  const [isChanged, setIsChanged] = React.useState(false);

  const formik = useFormik({
    validationSchema,
    initialValues: { activities: value },
    onSubmit: (values) => {
      onChange && onChange(values.activities);
    },
  });

  const { values, handleSubmit, handleChange, setValues, setFieldValue } =
    formik;

  React.useEffect(() => {
    if (isChanged) {
      handleSubmit();

      setIsChanged(false);
    }
  }, [handleSubmit, isChanged]);

  const addRowHandler = React.useCallback(() => {
    const val = [...values.activities];
    val.push({
      blockActivityTypeId: 0,
      activityItemTypeId: 0,
      itemTypeId: 0,
      code: "",
      name: "",
      color: "#000",
      icon: "",
    });

    setValues({ ...values, activities: val });
    setIsChanged(true);
  }, [setValues, values]);

  const handleDragEnded = React.useCallback(
    (param) => {
      const { source, destination } = param;

      let activities = [...values.activities];
      const _item = activities.splice(source.index, 1)[0];
      activities.splice(destination.index, 0, _item);

      setValues({ ...values, activities });
      setIsChanged(true);
    },
    [setValues, values]
  );

  const handleDelete = React.useCallback(
    (index) => {
      let activities = [...values.activities];
      activities = activities.filter((_, i) => i !== index);
      setValues({ ...values, activities });
      setIsChanged(true);
    },
    [setValues, values]
  );

  const handleDuplicate = React.useCallback(
    (index) => {
      let activities = [...values.activities];
      const findDuplicate = activities.find((_, i) => i === index);

      activities.splice(index, 0, findDuplicate);

      setValues({ ...values, activities });
      setIsChanged(true);
    },
    [setValues, values]
  );

  const handleUpdate = React.useCallback(() => setIsChanged(true), []);

  return (
    <>
      <ItemRowWrapper>
        {values.activities.length > 0 ? (
          <DragDropContext onDragEnd={handleDragEnded}>
            <Droppable droppableId="item__list">
              {(provided) => (
                <div
                  className="item__list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {values.activities.map((d, i) => {
                    return (
                      <Draggable
                        key={`key${i}`}
                        draggableId={`darg${i}`}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <ItemRow
                            className={[
                              "input-row ",
                              snapshot.isDragging ? "is-dragging" : "",
                            ].join("")}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            {/* <div className="item__inner"> */}
                            <div className="inputs">
                              {/* <ColorButton /> */}
                              <CustomColorPicker
                                value={d.color}
                                onChange={(color) => {
                                  setFieldValue(`activities.${i}.color`, color);
                                  setIsChanged(true);
                                }}
                              />

                              <div className="split"></div>

                              <input
                                name={`activities.${i}.code`}
                                className="code-input"
                                placeholder="Code"
                                type="text"
                                value={d.code}
                                onChange={handleChange}
                                onBlur={handleUpdate}
                              />

                              <div className="split"></div>

                              <input
                                className="name"
                                name={`activities.${i}.name`}
                                placeholder="Activity Name"
                                type="text"
                                value={d.name}
                                onChange={handleChange}
                                onBlur={handleUpdate}
                              />

                              <div className="split"></div>

                              <IconPicker
                                value={d.icon}
                                onChange={(iconName) => {
                                  setFieldValue(
                                    `activities.${i}.icon`,
                                    iconName
                                  );
                                  setIsChanged(true);
                                }}
                              />

                              {/* <IconButton
                                type="button"
                                className="icon_selector"
                                icon={<IconImage />}
                                btnIconSize={16}
                                btnSize={24}
                              /> */}
                            </div>
                            <div className="controller">
                              <div className="action">
                                <IconButton
                                  type="button"
                                  icon={<IconDuplicate />}
                                  btnIconSize={16}
                                  btnSize={24}
                                  onClick={() => handleDuplicate(i)}
                                />

                                <IconButton
                                  type="button"
                                  icon={<IconDelete />}
                                  btnIconSize={16}
                                  btnSize={24}
                                  onClick={() => handleDelete(i)}
                                />
                              </div>

                              <IconButton
                                as="div"
                                icon={<IconDrag />}
                                btnIconSize={16}
                                btnSize={24}
                                {...provided.dragHandleProps}
                              />
                              {/* </div> */}
                            </div>
                          </ItemRow>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : null}

        <div className="item__action">
          <button type="button" onClick={addRowHandler}>
            <IconButton
              as="span"
              icon={<IconAdd1 />}
              btnSize={12}
              btnIconSize={12}
            />
            Add New Activity
          </button>
        </div>
      </ItemRowWrapper>
    </>
  );
}

export default ActivityList;
