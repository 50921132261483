import React from "react";
import { ColorBtnItem, ColorButton, ColorPopoverPanel } from "./styled";

const _defaultColors = [
  "#A7C7E7",
  "#B2F2BB",
  "#E6E6FA",
  "#F4C2C2",
  "#FFDAB9",
  "#D3D3D3",
  "#F5F5DC",
  "#87CEEB",
  "#FFFFE0",
  "#FFFDD0",
];

const ColorPickerContext = React.createContext({
  currentColor: _defaultColors[0],
  colors: _defaultColors,
  colorBtnElement: null,
  setColorBtnElement: (el) => {},
  setColors: (colors) => {},
  setCurrentColor: (color) => {},
  onChange: (color) => {},
});

const useColor = () => {
  return React.useContext(ColorPickerContext);
};

const ColorPickerProvider = ({
  children,
  defaultColor,
  defaultColorPalette,
  onChange,
}) => {
  const [currentColor, setCurrentColor] = React.useState(null);
  const [colors, setColors] = React.useState(defaultColorPalette);
  const [colorBtnElement, setColorBtnElement] = React.useState(null);

  React.useEffect(() => {
    setCurrentColor(defaultColor);
  }, [defaultColor]);

  const values = {
    currentColor,
    colors,
    colorBtnElement,
    setColors,
    setCurrentColor,
    setColorBtnElement,
    onChange,
  };

  return (
    <ColorPickerContext.Provider value={values}>
      {children}
    </ColorPickerContext.Provider>
  );
};

const CustomColorPicker = (props) => {
  const {
    value = _defaultColors[0],
    colorPalette = _defaultColors,
    onChange,
    ...otherProps
  } = props;

  return (
    <ColorPickerProvider
      defaultColor={value}
      defaultColorPalette={colorPalette}
      onChange={onChange}
      {...otherProps}
    >
      <ColorPickerButton />
      <ColorPopover />
    </ColorPickerProvider>
  );
};

export default CustomColorPicker;

function ColorPickerButton() {
  const { setColorBtnElement, currentColor } = useColor();

  return (
    <ColorButton
      type="button"
      onClick={(e) => {
        setColorBtnElement(e.currentTarget);
      }}
      $color={currentColor}
    />
  );
}

function ColorPopover() {
  const {
    colorBtnElement,
    colors,
    currentColor,
    setColorBtnElement,
    setCurrentColor,
    onChange,
  } = useColor();

  if (!colorBtnElement) return null;

  return (
    <ColorPopoverPanel
      anchorEl={colorBtnElement}
      appendTo={document.body}
      onOutsideClick={() => setColorBtnElement(null)}
      //   hideOverlay
    >
      <div className="color-popover">
        {colors.map((color, i) => {
          return (
            <ColorBtnItem
              type="button"
              key={i}
              $color={color}
              $isActive={currentColor === color}
              onClick={() => {
                setCurrentColor(color);
                onChange(color);
              }}
            />
          );
        })}
      </div>
    </ColorPopoverPanel>
  );
}
