import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DocumentView, {
  EnforceProtectionTypes,
  Enforce_Protection_Password,
  ToolbarItems,
} from "../../ui-components/DocumentView";

import Button from "../../ui-components/Buttons/Button";
import FloatActionBar from "../../ui-components/Container/FloatActionBar";
import {
  ONBOARDING_STATUS,
  VIEW_CONTRACT_SOURCES,
} from "../../../constant/Status";
import useDocumentView, {
  ToolbarFilterActions,
} from "../../ui-components/DocumentView/hooks/useDocumentView";

import {
  getDocumentBase64ByDocId,
  getDocumentSFDTByDocId,
  saveDocumentToDocx,
} from "../../../Actions/Document";

import {
  contractGenerate,
  getProjectContractRequestInfo,
  getProjectMemberByProjectMemberId,
  projectContractAcceptOrReject,
  saveProjectContractRequest,
  setContractDocumentByIds,
  setProjectMemberStatus,
} from "../../../Actions/Project";

import {
  createDocument,
  getDocumentInfoById,
} from "../../../Actions/Templates";
import { useAppStore } from "../../../store/AppStore/AppStore";
import { useProjectStore } from "../../../store/ProjectStore";
import useGeneratePath from "../../../hooks/useGeneratePath";
import { ROUTE_OPTIONS } from "../../../constant/route-options";
import TXTextControlDocumentView from "../../../pages/Template/components/TXTextController/components/TXTextControlDocumentView";
import Alert from "../../../utility/alert";

const defaultToolbarItems = Object.keys(ToolbarItems);

export const ViewContractRights = {
  viewOnly: "ONLY_VIEW",
  viewAndEdit: "VIEW_AND_EDIT",
};

const ViewContract = () => {
  const params = useParams();
  const { state: locationState } = useLocation();
  const docRef = React.useRef();
  const { genViewContractPath } = useGeneratePath();

  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  const { filterToolbarItems } = useDocumentView({
    toolbarItems: defaultToolbarItems,
  });

  // Editor Container ref object

  const [documentContainer, setDocumentContainer] = React.useState(null);
  const [toolbarItems, setToolbarItems] = React.useState(defaultToolbarItems);
  const [sfdtData, setSFDTData] = React.useState(null);
  const [docBase64Data, setDocBase64Data] = React.useState(null);
  const [documentInfo, setDocumentInfo] = React.useState({
    userDocumentId: 0,
    userId: "",
    documentTypeId: 0,
    projectId: 0,
    templateId: 0,
    senderNote: "",
    documentURL: "",
    remarks: "",
    status: 0,
  });
  const [contractRequestInfo, setContractRequestInfo] = React.useState(null);
  const [isGenerateRequestMode, setIsGenerateRequestMode] =
    React.useState(false);

  const [isContractHold, setIsContractHold] = React.useState(false);

  // const member = useProjectStore((state) => state.projectSelectedMember);
  const [member, setMember] = React.useState(null);
  const navigate = useNavigate();
  const editorAttributes = React.useRef({
    isGenerateRequestMode: false,
  });
  const [documentState, setDocumentState] = React.useState({
    isDocumentLoaded: false,
  });
  const [isContractAcceptable, setIsContractAcceptable] = React.useState(false);

  console.log("member ", member, member?.statusId, 7);
  console.log("contractRequestInfo  ", contractRequestInfo);

  const getMemberDetails = React.useCallback(() => {
    if (!contractRequestInfo?.projectMemberId) {
      return;
    }

    getProjectMemberByProjectMemberId(contractRequestInfo?.projectMemberId)
      .then((res) => {
        const data = res?.result[0];

        console.log("res ", data);

        const m = {
          ...data,
          statusId: data?.userCurrentStatusIdInProject,
        };

        console.log("m ", m);
        setMember(m);
        if (
          m?.userCurrentStatusIdInProject ===
            ONBOARDING_STATUS.ContractGenerated ||
          m?.userCurrentStatusIdInProject === ONBOARDING_STATUS.ContractSent
        ) {
          setIsContractAcceptable(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [contractRequestInfo?.projectMemberId]);

  React.useEffect(() => {
    getMemberDetails();
  }, [getMemberDetails]);

  React.useEffect(() => {
    return () => {
      if (documentContainer) {
        documentContainer.destroy();
      }
    };
  }, [documentContainer]);

  /**
   * * URL params
   */
  const { contractId, source, userId, contractRequestId } = params;

  const onDocumentCreatedHandler = React.useCallback(
    (documentRef) => {
      if (!documentRef) {
        return;
      }

      const editorOptions = () => {
        documentRef.documentEditor.editor.enforceProtection(
          Enforce_Protection_Password,
          EnforceProtectionTypes.ReadOnly
        );

        documentRef.documentEditor.restrictEditing = true;
        documentRef.documentEditor.isReadOnly = true;
        documentRef.documentEditor.showComments = true;
        documentRef.documentEditor.showPropertiesPane = false;

        // documentRef.showProperties(false);
        documentRef.showHidePropertiesPane(false);
        documentRef.documentEditor.showRestrictEditingPane(false);
        // remove html btn
        documentRef.toolbarModule.propertiesPaneButton.element.style.display =
          "none";
        // documentRef.toolbarModule.propertiesPaneButton.element.remove()
      };

      setDocumentContainer(documentRef);
      console.log("onDocumentCreatedHandler source ", source);

      // Enforce Protection
      switch (source) {
        case VIEW_CONTRACT_SOURCES.hr: {
          console.log("source ", source);

          if (documentRef) {
            setTimeout(() => {
              documentRef.documentEditor.editor.stopProtection(
                Enforce_Protection_Password
              );
            }, 1000);

            documentRef.documentEditor.restrictEditing = false;
            documentRef.documentEditor.isReadOnly = false;

            console.log("documentRef ", documentRef);
          }

          // editorOptions();
          // documentRef.documentEditor.editor.stopProtection(
          //   Enforce_Protection_Password
          // );

          // documentRef.documentEditor.restrictEditing = false;;
          // documentRef.documentEditor.isReadOnly = false;
          // documentRef.documentEditor.showComments = true;
          // documentRef.documentEditor.showPropertiesPane = false;
          return;
        }

        case VIEW_CONTRACT_SOURCES.onboarding: {
          editorOptions();
          return;
        }

        case VIEW_CONTRACT_SOURCES.outside: {
          setTimeout(() => {
            if (documentRef.documentEditor.editor) {
              documentRef.documentEditor.editor.enforceProtection(
                Enforce_Protection_Password,
                EnforceProtectionTypes.CommentsOnly
              );
              documentRef.documentEditor.editor.deleteAllComments();
              documentRef.documentEditor.restrictEditing = false;
              documentRef.documentEditor.showComments = false;
              documentRef.documentEditor.showPropertiesPane = false;
              documentRef.documentEditor.isReadOnly = true;

              document.getElementsByClassName(
                "e-de-restrict-pane"
              )[0].style.display = "none";
            }
          }, 1000);

          // editorOptions();

          return;
        }

        default:
          return null;
      }
    },
    [source]
  );

  const handleTXTDocumentCreated = ({
    sourceHRAccess,
    sourceOnBoardingAccess,
    sourceOutsideAccess,
  }) => {
    // Enforce Protection
    switch (source) {
      case VIEW_CONTRACT_SOURCES.hr: {
        // Full Edit access
        sourceHRAccess();
        return;
      }

      case VIEW_CONTRACT_SOURCES.onboarding: {
        // Read Only, Comments allow
        sourceOnBoardingAccess();
        return;
      }

      case VIEW_CONTRACT_SOURCES.outside: {
        // Read only, Comments allow
        // Remove previous comments
        sourceOutsideAccess();
        return;
      }

      default:
        return null;
    }
  };

  /**
   * Get Document sfdt data format form API
   */
  const getDocTemplate = React.useCallback(() => {
    if (!contractId) {
      return;
    }

    // * Get template data from API
    getDocumentSFDTByDocId(contractId).then((res) => {
      if (!res?.isSuccess) {
        return;
      }
      setSFDTData(res?.result);
    });

    getDocumentBase64ByDocId(contractId).then((res) => {
      if (!res?.isSuccess) {
        return;
      }
      setDocBase64Data(res?.result);
    });

    // * Get and set document info
    getDocumentInfoById(contractId)
      .then((res) => {
        if (!res.isSuccess) {
          return;
        }

        setDocumentInfo(res?.result);
      })
      .catch((err) => {});

    //  * Get Project Contract Request Info
    getProjectContractRequestInfo(contractRequestId)
      .then((res) => {
        // if (!res.isSuccess) {
        //   return;
        // }

        setContractRequestInfo(res?.result || null);
      })
      .catch((err) => {
        console.error("setContractRequestInfo error ", err);
      });
  }, [contractId, contractRequestId]);

  React.useEffect(() => {
    const onboardOutside = () => {
      const _toolbarItems = filterToolbarItems(
        ToolbarFilterActions.RemoveThese,
        [
          ToolbarItems.New,
          ToolbarItems.Open,
          ToolbarItems.Separator,
          ToolbarItems.Undo,
          ToolbarItems.Redo,
          // ToolbarItems.RestrictEditing,
        ]
      );

      // setToolbarItems(_toolbarItems);
    };

    switch (source) {
      case VIEW_CONTRACT_SOURCES.hr: {
        // const _toolbarItems = filterToolbarItems(
        //   ToolbarFilterActions.RemoveThese,
        //   [ToolbarItems.RestrictEditing]
        // );

        // setToolbarItems(_toolbarItems);

        return;
      }

      case VIEW_CONTRACT_SOURCES.onboarding: {
        onboardOutside();
        return;
      }

      case VIEW_CONTRACT_SOURCES.outside: {
        onboardOutside();
        return;
      }

      default:
        return null;
    }
  }, [source, filterToolbarItems, documentContainer]);

  // Generate Request Contact API Call function.
  const onRequestContract = React.useCallback(async () => {
    try {
      if (!isGenerateRequestMode) {
        return;
      }

      showLoader();

      console.log("isGenerateRequestMode ", isGenerateRequestMode);
      console.log("contractRequestInfo ", contractRequestInfo);

      // Get Document data
      const data = await docRef.current.handleSaveDocAsDocX({
        documentName: "loop_doc",
      });

      if (!data) {
        hideLoader();
        return;
      }

      // 1 : Save Document and get URL from API
      const createDocRes = await saveDocumentToDocx(data);

      if (!createDocRes.isSuccess) {
        toast.error(createDocRes?.status);
        hideLoader();
        return;
      }

      const documentURL = createDocRes.blob?.uri;

      // *Get contract information
      // const {} = contractRequestInfo;

      // 2: create new document
      const saveDocRes = await createDocument({
        userDocumentId: 0,
        documentTypeId: documentInfo?.documentTypeId,
        projectId: documentInfo?.projectId,
        templateId: documentInfo?.templateId,
        documentURL,
        remarks: "",
        senderNote: "",
        userId,
      });

      if (!saveDocRes.isSuccess) {
        hideLoader();
        return;
      }

      const userDocumentId = saveDocRes?.result?.userDocumentId;

      // * 3: Get contract information
      const contractBodyData = {
        contractFee: contractRequestInfo?.contractFee,
        hrInstruction: contractRequestInfo?.hrInstruction,
        jobTitleId: contractRequestInfo?.jobTitleId,
        projectMemberID: contractRequestInfo?.projectMemberId,
        templateId: contractRequestInfo?.templateId,
        startDate: contractRequestInfo?.startDate,
        endDate: contractRequestInfo?.endDate,
      };

      const contractRes = await saveProjectContractRequest(contractBodyData);

      if (!contractRes?.data.isSuccess) {
        hideLoader();
        return;
      }

      const _contractRequestId = contractRes?.data?.result?.contractRequestId;

      // 4 : Set Contract Document By Ids API Call
      const contractDocumentRes = await setContractDocumentByIds(
        _contractRequestId,
        userDocumentId
      );

      if (!contractDocumentRes?.isSuccess) {
        toast.error(contractDocumentRes?.displayMessage);
        hideLoader();
        return;
      }

      // toast.success(contractDocumentRes?.displayMessage);
      setIsGenerateRequestMode(false);
      editorAttributes.current.isGenerateRequestMode = false;
      setIsContractHold(true);
      hideLoader();
      navigate(-1);
    } catch (error) {
      toast.error(error);
    }
  }, [
    navigate,
    isGenerateRequestMode,
    contractRequestInfo,
    documentInfo,
    userId,
    hideLoader,
    showLoader,
  ]);

  const onChangeRequest = React.useCallback(() => {
    // if (!documentContainer) {
    //   return;
    // }

    setIsGenerateRequestMode(true);
    editorAttributes.current.isGenerateRequestMode = true;

    // HR
    // if (VIEW_CONTRACT_SOURCES.hr === source) {
    //   documentContainer.showPropertiesPane = false;
    //   documentContainer.documentEditor.showComments = true;

    //   documentContainer.documentEditor.editor.enforceProtection(
    //     Enforce_Protection_Password,
    //     EnforceProtectionTypes.CommentsOnly
    //   );

    //   return;
    // }

    // Onboarding
    // if (VIEW_CONTRACT_SOURCES.onboarding === source) {
    //   //
    //   documentContainer.documentEditor.restrictEditing = true;
    //   documentContainer.documentEditor.isReadOnly = true;
    //   documentContainer.documentEditor.showComments = true;

    //   documentContainer.documentEditor.editor.enforceProtection(
    //     Enforce_Protection_Password,
    //     EnforceProtectionTypes.CommentsOnly
    //   );

    //   return;
    // }

    // Outside
    // if (VIEW_CONTRACT_SOURCES.outside === source) {
    //   //
    //   documentContainer.documentEditor.restrictEditing = true;
    //   documentContainer.documentEditor.isReadOnly = true;
    //   documentContainer.documentEditor.showComments = true;

    //   documentContainer.documentEditor.editor.enforceProtection(
    //     Enforce_Protection_Password,
    //     EnforceProtectionTypes.CommentsOnly
    //   );
    // }
  }, [documentContainer, source]);

  // Accept Decline
  const onAcceptDeclineHandler = React.useCallback(
    (status) => {
      showLoader();
      projectContractAcceptOrReject({
        acceptOrRejectRemarks: "",
        contractId,
        contractRequestId,
        isAcceptOrReject: status,
      }).then((response) => {
        if (!response?.isSuccess) {
          Alert.confirm({
            allowOutsideClick: false,
            title: "Error!",
            text: response?.result.message,
            confirmButtonText: "OK",
            showCancelButton: false,
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("Accepted...!");
            }
          });
          // toast.error(res?.displayMessage);
          hideLoader();
          return;
        }

        // toast.success(res?.displayMessage);
        setIsContractHold(true);
        navigate(`/${ROUTE_OPTIONS.dashboard.path}`);
        hideLoader();
      });
    },
    [contractId, contractRequestId, hideLoader, navigate, showLoader]
  );

  const onHRSaveHandler = React.useCallback(async () => {
    try {
      showLoader();

      // documentContainer.documentEditor.editor.deleteAllComments();

      // Get Document data
      const data = await docRef.current.handleSaveDocAsDocX({
        documentName: "loop_doc",
      });

      if (!data) {
        hideLoader();
        return;
      }

      // 1 : Save Document and get URL from API
      const createDocRes = await saveDocumentToDocx(data);

      if (!createDocRes.isSuccess) {
        toast.error(createDocRes?.status);
        hideLoader();
        return;
      }

      const documentURL = createDocRes.blob?.uri;

      // 2: create new document
      const saveDocRes = await createDocument({
        userDocumentId: documentInfo?.userDocumentId,
        documentTypeId: documentInfo?.documentTypeId,
        projectId: documentInfo?.projectId,
        templateId: documentInfo?.templateId,
        documentURL,
        remarks: "",
        senderNote: "",
        userId,
      });

      if (!saveDocRes.isSuccess) {
        toast.error(saveDocRes?.errorMessages);
        hideLoader();
        return;
      }

      // Update Member status
      const statusRes = await setProjectMemberStatus({
        projectMemberId: member?.projectMemberId,
        projectMemberStatusId: 16,
        contractRequestId: 0,
        contractId: 0,
        contractDocUrl: null,
      });

      console.log("statusRes ", statusRes);

      if (!statusRes.isSuccess) {
        toast.error(statusRes?.errorMessages);
        hideLoader();
        return;
      }

      // toast.success(statusRes?.displayMessage);
      hideLoader();
      navigate(-1);
    } catch (error) {
      hideLoader();
      console.log("Save Error ", error);
    }
  }, [
    showLoader,
    navigate,
    documentInfo,
    hideLoader,
    userId,
    member?.projectMemberId,
  ]);

  const onHRCompleteHandler = React.useCallback(async () => {
    try {
      showLoader();

      // documentContainer.documentEditor.editor.deleteAllComments();

      // Get Document data
      const data = await docRef.current.handleSaveDocAsDocX({
        documentName: "loop_doc",
      });

      if (!data) {
        hideLoader();
        return;
      }

      // 1 : Save Document and get URL from API
      const createDocRes = await saveDocumentToDocx(data);

      if (!createDocRes.isSuccess) {
        toast.error(createDocRes?.status);
        hideLoader();
        return;
      }

      const documentURL = createDocRes.blob?.uri;

      // 2: create new document
      const saveDocRes = await createDocument({
        userDocumentId: 0,
        documentTypeId: documentInfo?.documentTypeId,
        projectId: documentInfo?.projectId,
        templateId: documentInfo?.templateId,
        documentURL,
        remarks: "",
        senderNote: "",
        userId,
      });

      if (!saveDocRes.isSuccess) {
        toast.error(saveDocRes?.errorMessages || saveDocRes?.displayMessage);
        hideLoader();
        return;
      }

      // Update Member status
      const statusRes = await setProjectMemberStatus({
        projectMemberId: member?.projectMemberId,
        projectMemberStatusId: 17,
        contractRequestId: 0,
        contractId: 0,
        contractDocUrl: null,
      });

      if (!statusRes.isSuccess) {
        toast.error(saveDocRes?.errorMessages || saveDocRes?.displayMessage);
        hideLoader();
        return;
      }

      // 4 : Set Contract Document By Ids API Call
      const contractDocumentRes = await setContractDocumentByIds(
        contractRequestId,
        // member?.contractRequestId,
        saveDocRes?.result?.userDocumentId
      );

      if (!contractDocumentRes?.isSuccess) {
        toast.error(contractDocumentRes?.displayMessage);
        hideLoader();
        return;
      }

      // toast.success(contractDocumentRes?.displayMessage);
      // toast.success("Acceptance completed successfully");
      hideLoader();
      navigate(-1);

      //
    } catch (error) {
      console.log("error ", error);
      hideLoader();
    }
  }, [
    showLoader,
    documentInfo,
    hideLoader,
    userId,
    member?.projectMemberId,
    // member?.contractRequestId,
    contractRequestId,
    navigate,
  ]);

  const onSendHandler = React.useCallback(async () => {
    try {
      showLoader();

      // Get Document data
      const data = await docRef.current.handleSaveDocAsDocX({
        documentName: "loop_doc",
      });

      if (!data) {
        hideLoader();
        return;
      }

      // 1 : Save Document and get URL from API
      const createDocRes = await saveDocumentToDocx(data);

      if (!createDocRes.isSuccess) {
        toast.error(createDocRes?.status);
        hideLoader();
        return;
      }

      const documentURL = createDocRes.blob?.uri;

      // 2: create new document
      const saveDocRes = await createDocument({
        userDocumentId: 0,
        documentTypeId: documentInfo?.documentTypeId,
        projectId: documentInfo?.projectId,
        templateId: documentInfo?.templateId,
        documentURL,
        remarks: "",
        senderNote: "",
        userId,
      });

      if (!saveDocRes.isSuccess) {
        toast.error(saveDocRes?.errorMessages || saveDocRes?.displayMessage);
        hideLoader();
        return;
      }

      // 4 : Set Contract Document By Ids API Call
      const contractDocumentRes = await setContractDocumentByIds(
        contractRequestId,
        saveDocRes?.result?.userDocumentId
      );

      if (!contractDocumentRes?.isSuccess) {
        toast.error(contractDocumentRes?.displayMessage);
        hideLoader();
        return;
      }

      const baseUrl = window.location.origin;

      const viewPath = genViewContractPath({
        userId,
        contractId: saveDocRes?.result?.userDocumentId,
        contractRequestId,
        source: VIEW_CONTRACT_SOURCES.outside,
      });

      // Update Member status
      const statusRes = await setProjectMemberStatus({
        projectMemberId: member?.projectMemberId,
        projectMemberStatusId: ONBOARDING_STATUS.ContractSent,
        contractRequestId: 0,
        contractId: 0,
        contractDocUrl: `${baseUrl}${viewPath}`,
      });

      if (!statusRes.isSuccess) {
        toast.error(saveDocRes?.errorMessages || saveDocRes?.displayMessage);
        hideLoader();
        return;
      }

      // toast.success(statusRes?.displayMessage);
      hideLoader();
      navigate(-1);

      //
    } catch (error) {
      console.log("error ", error);
      hideLoader();
    }
  }, [
    showLoader,
    documentInfo?.documentTypeId,
    documentInfo?.projectId,
    documentInfo?.templateId,
    member?.projectMemberId,
    userId,
    contractRequestId,
    genViewContractPath,
    hideLoader,
    navigate,
  ]);

  const outsideActions = React.useMemo(() => {
    const s = [ONBOARDING_STATUS.ContractChangeRequested];

    console.log("isGenerateRequestMode ", isGenerateRequestMode);
    console.log("member?.statusId ", member?.statusId);
    console.log("contractRequestInfo 842:  ", contractRequestInfo);

    const isContractActive = contractRequestInfo?.isActive;

    if (s.includes(member?.statusId ) || isContractActive == false ) {
      return (
        <>
          {/* When once contract change requested, Accept, Decline and change request btn will disabled */
          /* When a new contract is generated and old contract is opened, Accept, Decline and change request btn will disabled */}
          <Button variant="gray-400" onClick={() => navigate(-1)}>
            Back
          </Button>
        </>
      );
    }

    return (
      <>
        <>
          <Button
            variant="gray-400"
            onClick={() => {
              navigate(-1);
              editorAttributes.current.isGenerateRequestMode = false;
            }}
          >
            Back
          </Button>

          {isContractAcceptable && (
            <Button
              variant={isGenerateRequestMode ? "primary" : "green-light"}
              onClick={
                isGenerateRequestMode ? onRequestContract : onChangeRequest
              }
            >
              {isGenerateRequestMode ? "Request Changes" : "Change Request"}
            </Button>
          )}

          {/* Only show on page load and Generate contract request not enabled */}
          {isContractAcceptable && !isGenerateRequestMode && (
            <>
              <Button
                variant="secondary"
                onClick={() => onAcceptDeclineHandler(2)}
              >
                Decline
              </Button>
              <Button
                variant="primary"
                onClick={() => onAcceptDeclineHandler(1)}
              >
                Accept
              </Button>
            </>
          )}
        </>
      </>
    );
  }, [
    onChangeRequest,
    onAcceptDeclineHandler,
    isGenerateRequestMode,
    isContractAcceptable,
    onRequestContract,
    member,
    navigate,
    contractRequestInfo,
  ]);


  const onBackRequestModeHandler = () => {
    // documentContainer.documentEditor.restrictEditing = true;
    // documentContainer.documentEditor.isReadOnly = true;
    // documentContainer.documentEditor.showComments = true;
    setIsGenerateRequestMode(false);

    documentContainer.documentEditor.editor.deleteComment();

    documentContainer.documentEditor.editor.enforceProtection(
      Enforce_Protection_Password,
      EnforceProtectionTypes.ReadOnly
    );

    return;
  };

  /**
   * * Render Action buttons according to the source type.
   */
  const renderAccessRightButtons = React.useMemo(() => {
    if (!member?.statusId) {
      return null;
    }

    switch (source) {
      case VIEW_CONTRACT_SOURCES.hr: {
        const notAllowedOnboardStatuses = [ONBOARDING_STATUS.ContractSent];
        // View only
        return (
          <>
            <Button variant="gray-400" onClick={() => navigate(-1)}>
              Back
            </Button>

            {/* status  <  17 */}
            {member?.statusId &&
            member?.statusId < ONBOARDING_STATUS.ContractAcceptedByHR &&
            !notAllowedOnboardStatuses.includes(member?.statusId) ? (
              <>
                <Button variant="secondary" onClick={onHRSaveHandler}>
                  Save
                </Button>

                {/* onHRGenerateContract old action */}
                <Button variant="primary" onClick={onHRCompleteHandler}>
                  Complete
                </Button>
              </>
            ) : null}
          </>
        );
      }

      case VIEW_CONTRACT_SOURCES.onboarding: {
        console.log("renderAccessRightButtons ---> ", member?.statusId);

        const btnHideStatus = [
          ONBOARDING_STATUS.ContractChangeRequested,
          ONBOARDING_STATUS.ContractSent,
        ];

        return (
          <>
            <Button
              variant="gray-400"
              onClick={() => navigate(-1)}
              // onClick={() =>
              //   !isGenerateRequestMode
              //     ? navigate(-1)
              //     : onBackRequestModeHandler()
              // }
            >
              Back
            </Button>

            <>
              {!btnHideStatus.includes(member?.statusId) ? (
                <Button
                  variant={isGenerateRequestMode ? "primary" : "green-light"}
                  onClick={
                    isGenerateRequestMode ? onRequestContract : onChangeRequest
                  }
                >
                  {isGenerateRequestMode ? "Request Changes" : "Change Request"}
                </Button>
              ) : null}
            </>

            {!isGenerateRequestMode ? (
              <>
                {!btnHideStatus.includes(member?.statusId) ? (
                  <Button variant="primary" onClick={onSendHandler}>
                    Send
                  </Button>
                ) : null}
              </>
            ) : null}
          </>
        );
        // return outsideActions;
      }

      case VIEW_CONTRACT_SOURCES.outside: {
        return outsideActions;
      }

      default:
        return null;
    }
  }, [
    source,
    onHRSaveHandler,
    onHRCompleteHandler,
    navigate,
    isGenerateRequestMode,
    onRequestContract,
    onChangeRequest,
    onSendHandler,
    outsideActions,
    member?.statusId,
  ]);

  // Load SFDT Template data
  React.useEffect(() => {
    getDocTemplate();
  }, [getDocTemplate, params]);

  // React.useEffect(() => {
  //   if (!member) {
  //     navigate(-1);
  //     return;
  //   }
  // }, [member, navigate]);

  const onClick = () => {
    // documentContainer.documentEditor.editor.deleteAllComments();
    if (!documentContainer) {
      return;
    }

    window.d = documentContainer;
    window.ed = documentContainer.documentEditor;

    console.log("documentContainer.documentEditor ");

    documentContainer.showProperties(false);
    documentContainer.showHidePropertiesPane(false);
    documentContainer.documentEditor.showRestrictEditingPane(false);
  };

  const handleDocumentState = (values) => {
    if (values.isDocumentLoaded) {
      setDocumentState((prevState) => ({
        ...prevState,
        isDocumentLoaded: values.isDocumentLoaded,
      }));
    }
  };

  return (
    <>
      {/* <Container withGutterY={false} pageContentClassName="full--page"> */}
      {/* <DocumentView
        ref={docRef}
        enableToolbar={false}
        toolbarItems={toolbarItems}
        sfdtData={sfdtData}
        onDocumentCreated={onDocumentCreatedHandler}
      /> */}
      <TXTextControlDocumentView
        source={source}
        ref={docRef}
        documentData={docBase64Data}
        handleOnCreated={handleTXTDocumentCreated}
        editorAttributes={editorAttributes}
        handleDocumentState={handleDocumentState}
        member={member}
      />

      <>
        {documentState.isDocumentLoaded && renderAccessRightButtons ? (
          <FloatActionBar>{renderAccessRightButtons}</FloatActionBar>
        ) : null}
      </>

      {/* </Container> */}
    </>
  );
};

export default ViewContract;
