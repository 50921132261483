const config = {
  id: "setting_1",
  menuIcon: "fa-solid fa-gears",
  label: "Setting",
  path: "/setting-ui",
  children: {
    project: {
      id: "project-settings",
      label: "Project",
      path: "project/:id",
      children: {
        /**
         * Permission
         */
        permission: {
          id: "permission_1",
          path: "",
          toolbarLabel: "Settings",
          breadcrumbLabel: "Permission",
          label: "Permission",
        },

        /**
         * Project
         */
        project: {
          id: "setting/project",
          path: "project",
          toolbarLabel: "Settings",
          breadcrumbLabel: "Project",
          label: "Project",
        },

        /**
         * Template
         */
        template: {
          id: "setting/template",
          path: "template",
          toolbarLabel: "Settings",
          breadcrumbLabel: "Template",
          label: "Template",
        },

        /**
         * Common
         */
        common: {
          id: "setting/project/common",
          path: "common",
          toolbarLabel: "Settings",
          breadcrumbLabel: "Common",
          label: "Common",
          children: {
            client: {
              id: "common/client",
              path: "",
              label: "Client",
            },
            company: {
              id: "common/company",
              path: "company",
              label: "Company",
            },
          },
        },

        schedule: {
          id: "setting/schedule",
          path: "schedule",
          label: "Schedule",
          children: {
            activityBlock: {
              id: "schedule/activity_block",
              path: "",
              label: "Activity Block",
            },
          },
        },

        // scheduleItem: {
        //   id: "scheduleItem",
        //   path: "schedule-item",
        //   label: "Schedule Item",
        //   children: {
        //     activityBlock: {
        //       id: "activityBlock",
        //       path: "activity-block",
        //       label: "Activity Block",
        //     },
        //   },
        // },
      },
    },

    tenant: {
      id: "tenant-settings",
      path: "tenant/:id",
      label: "Settings",
      children: {
        permission: {
          id: "permission",
          path: "",
          label: "Permission",
          toolbarLabel: "Settings",
          breadcrumbLabel: "Permission",
        },
        client: {
          id: "tenant/client",
          path: "client",
          label: "Client",
          toolbarLabel: "Settings",
          breadcrumbLabel: "Permission",
          children: {
            view: {
              id: "tenant/client/view",
              path: "",
              label: "",
            },
            form: {
              id: "tenant/client/form",
              path: "form",
              label: "",
            },
          },
        },
        company: {
          id: "tenant/company",
          path: "company",
          label: "Company",
          toolbarLabel: "Settings",
          breadcrumbLabel: "Permission",
        },
      },
    },

    elements: {
      id: "ui-elements",
      path: "elements",
      label: "Elements",
    },
  },
};

export default config;
