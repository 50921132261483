import React from "react";
import Typography from "../../../../../../common/components/Typography";
import { StyledContainer } from "./styled";
import IconButton from "./../../../../../../common/components/Buttons/IconButton";
import useIcon from "./../../../../../../common/components/IconSelector/hooks/useIcon";

export const ViewTypes = {
  Vertical: 0,
  Horizontal: 1,
  VerticalIcon: 2,
  HorizontalIcon: 3,
  VerticalCompound: 4,
};

const ViewClassNames = {
  [ViewTypes.Vertical]: "vertical",
  [ViewTypes.Horizontal]: "horizontal",
  [ViewTypes.VerticalIcon]: "vertical_icon",
  [ViewTypes.HorizontalIcon]: "horizontal_icon",
  [ViewTypes.VerticalCompound]: "vertical_compound",
};

const ContentItem = ({
  title,
  icon,
  children,
  iconColor,
  viewType = ViewTypes.Vertical,
  render,
}) => {
  const getIcon = useIcon();

  const iconName = icon;

  const renderChild = React.useMemo(() => {
    if (render && typeof render === "function") {
      return render();
    }

    return (
      <Typography
        variant={Typography.font_variant.small}
        fontWeight={Typography.font_weight.regular}
      >
        {render}
      </Typography>
    );
  }, [render]);

  const renderIcon = React.useMemo(() => {
    if (iconName && typeof iconName === "string") {
      const IconComponent = getIcon(iconName);

      return (
        <IconButton
          as="div"
          btnIconSize={16}
          btnSize={20}
          icon={<IconComponent />}
          btnIconColor={iconColor || "var(--font-tint-04)"}
        />
      );
    }

    return null;
  }, [getIcon, iconName, iconColor]);

  return (
    <StyledContainer viewType={viewType} className={ViewClassNames[viewType] || ''}>
      {viewType !== ViewTypes.HorizontalIcon && (
        <div className="title">
          <Typography
            variant={Typography.font_variant.xSmall}
            fontWeight={Typography.font_weight.medium}
            color={Typography.font_color.tint_04}
            className="title__text"
          >
            {title}
          </Typography>
        </div>
      )}

      <div className="content">
        {viewType === ViewTypes.HorizontalIcon && renderIcon ? (
          <div className="start">{renderIcon}</div>
        ) : null}

        <div className="end">{children ? children : renderChild}</div>
      </div>
    </StyledContainer>
  );
};

export default ContentItem;
