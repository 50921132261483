// import { permissionLoader } from "../../../routes/loaders";
import React from "react";

import BreadcrumbLink from "../../../components/ui-components/Breadcrumb/BreadcrumbLink";
import config from "./config";
import Setting from "..";
import UIElements from "../screens/UIElements";
import { ProjectRoutes } from "./project/index.routes";
import { TenantRoutes } from "./tenant/index.routes";
import { permissionLoader } from "../../../routes/loaders";

const Project = React.lazy(() => import("../screens/Project"));
const Tenant = React.lazy(() => import("../screens/Tenant"));

const children = config.children;

export const SettingRoutes = {
  id: config.id,
  path: config.path,
  element: <Setting />,
  handle: {
    crumb: () => <BreadcrumbLink label={config.label} to={config.path} />,
  },
  children: [
    {
      id: children.project.id,
      path: children.project.path,
      element: (
        <React.Suspense fallback={<></>}>
          <Project />
        </React.Suspense>
      ),
      handle: {
        crumb: () => <BreadcrumbLink label={children.project.label} />,
      },
      loader: async () => {
        // return true;
        return await permissionLoader(children.project.id, "project-setting");
      },
      children: ProjectRoutes,
    },

    // tenant route
    {
      id: children.tenant.id,
      path: children.tenant.path,
      element: (
        <React.Suspense fallback={<></>}>
          <Tenant />
        </React.Suspense>
      ),
      handle: {
        crumb: () => <BreadcrumbLink label={children.tenant.label} />,
        config: () => children.tenant,
      },
      loader: async () => {
        // return true;
        return await permissionLoader(children.tenant.id, "tenant-setting");
      },
      children: TenantRoutes,
    },

    {
      id: children.elements.id,
      path: children.elements.path,
      element: <UIElements />,
      handle: {
        crumb: () => <BreadcrumbLink label={children.elements.label} />,
      },
      loader: async () => {
        return true;
        //   return await permissionLoader(children.common.id);
      },
    },
  ],

  loader: async () => {
    return true;
    //   return await permissionLoader(config.id);
  },
};

export const SettingRoutePaths = config;
