import React from "react";
import * as Styled from "./styled";
import { useState } from "react";

function WeekRow() {
  const [selectedDay, setSelectedDay] = useState("Sun");
  const [workDays, setWordDays] = useState(["Mon", "Tue", "Wed", "Thu", "Fri"]);

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const [startOrderedDays, setStartOrderedDays] = useState([
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ]);

  const handleDayClick = (day) => {
    setSelectedDay(day);

    const index = days.indexOf(day);

    const beforeChosen = days.slice(0, index);
    const afterChosen = days.slice(index + 1);

    setStartOrderedDays([day, ...afterChosen, ...beforeChosen]);
  };

  const handleWorkDayClick = (day) => {
    if (Array.isArray(workDays) && workDays.includes(day)) {
      const unselectArray = workDays.filter((item) => item !== day);
      setWordDays(unselectArray);
    } else {
      setWordDays([...workDays, day]);
    }
  };

  const activeSelectedDays = (day) => {
    if (Array.isArray(workDays) && workDays.includes(day)) {
      return ("day active");
    } else {
      return ("day");
    }
  };

  return (
    <>
      <Styled.Row>
        <div className="title">{"Week Start"}</div>

        <div className="daysContainer">
          {days.map((day, index) => (
            <button
              key={index}
              className={selectedDay === day ? "day active" : "day"}
              onClick={() => handleDayClick(day)}
            >
              {day}
            </button>
          ))}
        </div>
      </Styled.Row>

      <Styled.Row>
        <div className="title">{"Work Days"}</div>

        <div className="daysContainer">
          {startOrderedDays.map((day, index) => (
            <button
              key={index}
              className={activeSelectedDays(day)}
              onClick={() => handleWorkDayClick(day)}
            >
              {day}
            </button>
          ))}
        </div>
      </Styled.Row>
    </>
  );
}

export default WeekRow;
