import React from "react";
import { useSettingContext } from "../../../../../../../../shared/context/SettingContext";
import { Modal } from "./styled";
import Form from "./Form";
import { useActivityBlock } from "../../../context/ActivityBlockContext";

const MilestoneModal = ({ ...otherProps }) => {
  const { projectId, variant } = useSettingContext();
  const { milestoneModalData, MilestoneModal: MModal } = useActivityBlock();

  const isEditMode = milestoneModalData.payload?.type === "EDIT";

  const isOpen = milestoneModalData.visibility;

  const renderModalTitle = React.useMemo(() => {
    if (!isEditMode) {
      return "Add Milestone";
    }

    return "Edit Milestone";
  }, [isEditMode]);

  return (
    <Modal
      //   isLoading={false}
      title={renderModalTitle}
      subTitle="Manage your Milestone specific details here"
      visibility={isOpen}
      body={<BodyComponent />}
      onClose={MModal.close}
      {...otherProps}
    />
  );
};

function BodyComponent({ handleSubmit, formData }) {
  return <Form />;
}

export default MilestoneModal;
