import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import "../styles.scss";
import LocationItem from "./LocationListItem";

const LocationList = ({ children }) => {
  const [locationTimes, setLocationTimes] = useState([]);

  const updateTimeForLocation = (timeZone) => {
    const now = moment().tz(timeZone);
    return now.format("hh:mm A");
  };

  const items = [
    { area: "New York", timeZone: "America/New_York", isActive: false },
    { area: "Singapore", timeZone: "Asia/Singapore", isActive: true },
    { area: "Sydney", timeZone: "Australia/Sydney", isActive: false },
  ];
  const setUpdateTime = () => {
      // Update the times for all locations
      const updatedTimes = items.map((location) => ({
        ...location,
        time: updateTimeForLocation(location.timeZone),
      }));
      setLocationTimes(updatedTimes);
  }; 
  useEffect(() => {
    setUpdateTime();
    const interval = setInterval(() => {
      setUpdateTime();
    }, 60000); // Update every second

    return () => {
      clearInterval(interval);
    };
  }, []);


  return (
    <div className="location__list">
      {locationTimes.map((l, i) => (
        <LocationItem
          key={i}
          area={l.area}
          time={l.time}
          isActive={l.isActive}
        />
      ))}
    </div>
  );
};

export default LocationList;
