import React from "react";
import { Container } from "./styled";
import WidgetLink, { WidgetLinks } from "../../WidgetLink";
import TaskItem from "../../TaskItem";
import UIButton from "../../../../../../common/components/Buttons/UIButton";
import IconRightArrow from "../../../../../../common/components/icons/Line/Arrow/right";
import TaskItemGroup, { TaskList } from "../../TaskItemGroup";
import { ScrollY } from "../../../../styled/dashboard.styled";

const ThingsTodoWidget = () => {
  return (
    <Container>
      <WidgetLinks>
        <WidgetLink label="All Todo" />
        <WidgetLink label="Today's Todo" isActive />
      </WidgetLinks>

      <ScrollY $maxHeight={"300px"}>
        <TaskList>
          <TaskItemGroup date="23 June">
            <TaskItem
              count="3"
              actions={
                <>
                  <UIButton
                    variant={UIButton.Variant.Primary}
                    shade={200}
                    hoverShade={300}
                    rounded
                    size={UIButton.Sizes.Small}
                    endIcon={<IconRightArrow />}
                  >
                    Sign
                  </UIButton>
                </>
              }
              content={
                <>
                  <b>Contract</b> to be requested
                </>
              }
            />
            <TaskItem
              count="2"
              actions={
                <>
                  <UIButton
                    variant={UIButton.Variant.Primary}
                    shade={200}
                    hoverShade={300}
                    rounded
                    size={UIButton.Sizes.Small}
                    endIcon={<IconRightArrow />}
                  >
                    Sign
                  </UIButton>
                </>
              }
              content={
                <>
                  <b>Contract</b> to be requested
                </>
              }
            />
          </TaskItemGroup>

          <TaskItemGroup date="24 June">
            <TaskItem
              count="3"
              actions={
                <>
                  <UIButton
                    variant={UIButton.Variant.Primary}
                    shade={200}
                    hoverShade={300}
                    rounded
                    size={UIButton.Sizes.Small}
                    endIcon={<IconRightArrow />}
                  >
                    Sign
                  </UIButton>
                </>
              }
              content={
                <>
                  <b>Contract</b> to be requested
                </>
              }
            />
            <TaskItem
              count="2"
              actions={
                <>
                  <UIButton
                    variant={UIButton.Variant.Primary}
                    shade={200}
                    hoverShade={300}
                    rounded
                    size={UIButton.Sizes.Small}
                    endIcon={<IconRightArrow />}
                  >
                    Sign
                  </UIButton>
                </>
              }
              content={
                <>
                  <b>Contract</b> to be requested
                </>
              }
            />
          </TaskItemGroup>

          <TaskItemGroup date="25 June">
            <TaskItem
              count="3"
              actions={
                <>
                  <UIButton
                    variant={UIButton.Variant.Primary}
                    shade={200}
                    hoverShade={300}
                    rounded
                    size={UIButton.Sizes.Small}
                    endIcon={<IconRightArrow />}
                  >
                    Sign
                  </UIButton>
                </>
              }
              content={
                <>
                  <b>Contract</b> to be requested
                </>
              }
            />
            <TaskItem
              count="2"
              actions={
                <>
                  <UIButton
                    variant={UIButton.Variant.Primary}
                    shade={200}
                    hoverShade={300}
                    rounded
                    size={UIButton.Sizes.Small}
                    endIcon={<IconRightArrow />}
                  >
                    Sign
                  </UIButton>
                </>
              }
              content={
                <>
                  <b>Contract</b> to be requested
                </>
              }
            />
          </TaskItemGroup>

          <TaskItemGroup date="26 June">
            <TaskItem
              count="3"
              actions={
                <>
                  <UIButton
                    variant={UIButton.Variant.Primary}
                    shade={200}
                    hoverShade={300}
                    rounded
                    size={UIButton.Sizes.Small}
                    endIcon={<IconRightArrow />}
                  >
                    Sign
                  </UIButton>
                </>
              }
              content={
                <>
                  <b>Contract</b> to be requested
                </>
              }
            />
          </TaskItemGroup>
        </TaskList>
      </ScrollY>
    </Container>
  );
};

export default ThingsTodoWidget;
