import React from "react";

import { SubListWrapper } from "./styled";
import InputField from "../../../../../../../../../../../common/components/Form/InputField";
import IconButton from "../../../../../../../../../../../common/components/Buttons/IconButton";
import IconAdd1 from "../../../../../../../../../../../common/components/icons/Line/add-1";
// import IconEdit from "../../../../../../../../../../../common/components/icons/Line/Edit/01";
import IconDelete from "../../../../../../../../../../../common/components/icons/Line/Delete/01";

function SubStageList({ value = [], onChange }) {
  const [listValue, setListValue] = React.useState(updateInitValue(value));
  const [stageValue, setStageValue] = React.useState("");
  const inputRef = React.useRef();
  const [isChanged, setIsChanged] = React.useState(false);

  const handleAdd = React.useCallback(() => {
    if (!stageValue) return;

    setListValue((prev) => {
      let val = [
        ...prev,
        { value: 0, label: stageValue, id: new Date().getTime().toString(36) },
      ];

      return val;
    });

    setIsChanged(true);
    setStageValue("");
    inputRef?.current?.focus();
  }, [stageValue]);

  const handelDelete = React.useCallback((id) => {
    setListValue((prev) => {
      return prev.filter((d) => d?.id !== id);
    });

    setIsChanged(true);
  }, []);

  //   const handelEdit = React.useCallback((id) => {}, []);

  // Update callback
  React.useEffect(() => {
    if (!isChanged) {
      return;
    }

    const list = [...listValue].map((d) => {
      return { label: d?.label, value: d?.value };
    });

    onChange && onChange(list);
    setIsChanged(false);
  }, [isChanged, listValue, onChange]);

  return (
    <SubListWrapper>
      <div className="stage-control">
        <InputField
          ref={inputRef}
          placeholder="Enter Sub Stage Name"
          value={stageValue}
          onChange={(e) => setStageValue(e.target.value)}
        />
        <IconButton
          className="plus-btn"
          icon={<IconAdd1 />}
          btnIconSize={16}
          btnSize={28}
          type="button"
          onClick={handleAdd}
          disabled={!stageValue}
        />
      </div>

      {/* Render list */}
      {listValue.length > 0 ? (
        <div className="stage-list">
          {listValue.map((d, i) => {
            return (
              <div key={i} className="stage-list__item">
                <div className="stage-list__item--label">{d?.label}</div>
                <div className="stage-list__item--action">
                  {/* <IconButton
                    type="button"
                    btnSize={24}
                    icon={<IconEdit />}
                    onClick={() => handelEdit(d?.id)}
                  /> */}
                  <IconButton
                    type="button"
                    btnSize={24}
                    icon={<IconDelete />}
                    onClick={() => handelDelete(d?.id)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </SubListWrapper>
  );
}

const updateInitValue = (d) =>
  d.map((dd) => ({ ...d, id: new Date().getTime().toString(36) }));

const getValuesWithoutId = (d) =>
  d.map((dd) => ({ label: d?.label, value: d?.value }));

export default SubStageList;
