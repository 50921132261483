import React from "react";
import * as Styled from "./styled";
import { useScheduleContext } from "../../../../context/ScheduleContext";

const MonthColumn = () => {
  const { months, currentFocusDate } = useScheduleContext();

  return (
    <Styled.MonthColumn className="month__column" label="Mth" isFixed>
      <Styled.MonthItems>
        {months.map((month, i) => (
          <MonthItem
            key={i}
            month={month}
            value={i}
            isCurrent={new Date(currentFocusDate).getMonth() === i}
          />
        ))}
      </Styled.MonthItems>
    </Styled.MonthColumn>
  );
};

function MonthItem({ month, value, isCurrent }) {
  return <Styled.MonthItem $isCurrent={isCurrent}>{month}</Styled.MonthItem>;
}

export default MonthColumn;
