import styled from "styled-components";
import { StyledBoxButton } from "../../../../styled/Common/Buttons";
import UIButton from "../../Buttons/UIButton";
import { fontStyle } from "../../../../styled/utility";

export const UrlList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .input__wrapper {
    display: flex;
    /* align-items: center; */
    width: 100%;
    flex-direction: column;

    .form {
      display: flex;
      align-items: center;

      .form__inputs {
        display: grid;
        grid-template-columns: 1.4fr 2fr var(--layout-size-36);
        align-items: center;
        gap: var(--layout-spacing-8);
      }
    }

    .errors {
      display: grid;
      grid-template-columns: 1.4fr 2fr var(--layout-size-36);
      align-items: center;
      gap: var(--layout-spacing-8);

      .error {
        ${fontStyle("x-small-regular")};
        margin-top: var(--layout-spacing-4);
        color: var(--color-danger-300);
      }
    }
  }

  .current__list__wrapper {
    margin-top: var(--layout-spacing-16);
  }
`;

export const AddButton = styled(UIButton)`
  width: var(--layout-size-36);
  height: var(--layout-size-32);
  padding: 0;

  .icon {
    --icon-size: var(--layout-size-12);
    --icon-font-size: var(--layout-size-12);
  }
`;

export const IconButton = styled(StyledBoxButton)`
  width: var(--layout-size-24);
  height: var(--layout-size-24);
  color: var(--font-tint-04);

  .icon {
    --icon-size: var(--layout-size-16);
    --icon-font-size: var(--layout-size-16);
  }
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: var(--layout-spacing-4);
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--layout-spacing-16);
  background-color: var(--color-accent-50);
  border-radius: var(--layout-border-radius-4);
  border: 1px solid var(--color-accent-75);
  padding: var(--layout-spacing-4) var(--layout-spacing-8);
  padding-right: var(--layout-spacing-4);

  .start,
  .end {
    display: flex;
    align-items: center;

    .icon {
      --icon-size: var(--layout-size-16);
      --icon-font-size: var(--layout-size-16);
      color: var(--font-tint-04);
    }
  }

  .start {
    gap: var(--layout-spacing-16);

    .icon {
      --icon-size: var(--layout-size-12);
      --icon-font-size: var(--layout-size-12);
    }

    .link__text {
      text-decoration: underline;
    }
  }

  .end {
    gap: var(--layout-spacing-4);
  }
`;
