import React from "react";

import TanStackTable, {
  columnHelper,
  Elements,
} from "../../../../../../../../components/common/TanStackTable";
import { useSettingContext } from "../../../../../../../../shared/context/SettingContext";
import ViewSubStagePopover from "../../../../components/ui/ViewSubStagePopover";
import { FlexCenter } from "../../../../../../../../styled";
import { useActivityBlock } from "../../../context/ActivityBlockContext";
import { FilterArea } from "../styled";
import SelectField from "../../../../../../../../../../common/components/Form/SelectField";

const data = [
  {
    order: "Order",
    name: "Lorem ipsum",
    shortName: "C",
    id: "34",
  },
];

function MilestoneTable() {
  const { projectId, tenantId } = useSettingContext();
  const { MilestoneModal } = useActivityBlock();

  const columns = [
    columnHelper.accessor("order", {
      header: "Order",
      size: 50,
      cell: ({ row, getValue }) => {
        return getValue();
      },
    }),
    columnHelper.accessor("name", {
      header: "Name",
      size: 200,
      cell: ({ row, getValue }) => {
        return getValue();
      },
    }),

    columnHelper.accessor("shortName", {
      header: () => <FlexCenter>Short Name</FlexCenter>,
      size: 100,
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return <FlexCenter>{getValue()}</FlexCenter>;
      },
    }),

    columnHelper.accessor("id", {
      header: "Action",
      size: 30,
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <Elements.Actions gap={8}>
            <Elements.ActionButton
              type="edit"
              title="Edit"
              onClick={() => {
                MilestoneModal.open({
                  type: "EDIT",
                  id: 12,
                });
              }}
            />
            <Elements.ActionButton type="delete" />
            {/* <Elements.ActionButton type="duplicate" /> */}
          </Elements.Actions>
        );
      },
    }),
  ];

  return (
    <>
      <FilterArea>
        <SelectField options={[{ label: "Pre-Production", value: 1 }]} />
      </FilterArea>

      <TanStackTable
        enabledRowsSpace
        isLoading={false}
        columns={columns}
        data={data}
      />
      {/* Dialogs */}
    </>
  );
}

export default MilestoneTable;
