import React from "react";

import BaseDashboardBlock from "../../common/components/BaseDashboardBlock";
import DocumentWidget from "../../common/components/Widgets/DocumentWidget";
import TimeLineWidget from "../../common/components/Widgets/TimeLineWidget";
import NotesWidget from "../../common/components/Widgets/NotesWidget";
import { Container } from "../../styled/dashboard.styled";
import EventCalendar from "../../../../common/components/UI/EventCalendar";
import { useParams } from "react-router";
import ThingsTodoWidget from "../../common/components/Widgets/ThingsTodoWidget";
import NotificationWidget from "../../common/components/Widgets/NotificationWidget";

const notes = [
  "Check availability of DP",
  "New Idea Tibet Meditation discuss with steve after ...",
  "Equipment rental price list",
  "Check availability of DP",
  "New Idea Tibet Meditation discuss with steve after ...",
  "Equipment rental price list",
  "Check availability of DP",
  "New Idea Tibet Meditation discuss with steve after ...",
  "Equipment rental price list",
];

function ProjectDashboard() {
  const params = useParams();

  // Project Id
  const projectId = params?.id;

  return (
    <Container>
      <div className="dashboard_wrapper">
        <div className="dashboard_widgets">
          {/* 
          -------------------------
          TimeLine Widget
          -------------------------
          */}
          <TimeLineWidget />

          {/* 
          -------------------------
          Notes widget
          -------------------------
          */}
          <NotesWidget notes={notes.slice(0, 6)} />

          {/* 
          -------------------------
          Notification widget
          -------------------------
          */}
          <BaseDashboardBlock blockClassName="notification-widget">
            <NotificationWidget />
          </BaseDashboardBlock>

          {/* 
          -------------------------
          My Calendar
          -------------------------
          */}
          <BaseDashboardBlock title="My Calendar" blockClassName="my_calendar">
            <EventCalendar hideMainNote />
          </BaseDashboardBlock>

          {/* 
          -------------------------
          Things to-do widget
          -------------------------
          */}
          <BaseDashboardBlock title="Things to-do" blockClassName="todo">
            <ThingsTodoWidget />
          </BaseDashboardBlock>
          {/* 
          -------------------------
          Document Widget
          -------------------------
          */}
          <DocumentWidget projectId={projectId} />
        </div>
      </div>
    </Container>
  );
}

export default ProjectDashboard;
