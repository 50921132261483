import React from "react";

export const ToolbarFilterActions = {
  RemoveThese: 0,
  KeepThese: 1,
};

const useDocumentView = ({ toolbarItems = [] }) => {
  const [toolbarItemOptions, setToolbarItemOptions] =
    React.useState(toolbarItems);

  /**
   * * Remove the toolbar items
   * @param {[]} removeItems ['NEW','Open']
   * @return Returns new array of toolbar items
   */
  const filterToolbarItems = React.useCallback(
    (action, items = []) => {
      return [...toolbarItemOptions].filter((item) => {
        if (action === ToolbarFilterActions.RemoveThese) {
          return !items.includes(item);
          // return items.indexOf(item) <= 0;
        }

        // Keep These
        return items.indexOf(item) > -1;
      });
    },
    [toolbarItemOptions]
  );

  return {
    toolbarItemOptions,
    filterToolbarItems,
  };
};

export default useDocumentView;
