import React from "react";
import Popover from "../../../../../../../../../common/components/Popover";
import { Container } from "./styled";
import { ViewButton } from "../../../styled";
import IconView from "../../../../../../../../../common/components/icons/Line/View/01";

const ViewSubStagePopover = ({ data }) => {
  const [anchor, setAnchor] = React.useState(null);

  return (
    <>
      <ViewButton
        icon={<IconView />}
        btnIconSize={16}
        btnSize={28}
        onClick={(e) => {
          setAnchor(e.currentTarget);
        }}
      />

      {anchor ? (
        <Popover
          anchorEl={anchor}
          appendTo={document.body}
          onOutsideClick={() => setAnchor(null)}
          placement="right-start"
        >
          <Container>
            <div className="sub-stage">
              <SubListItem label="Shoot Script / Beat Sheet" />
              <SubListItem label="Shoot Script / Beat Sheet" />
              <SubListItem label="Shoot Script / Beat Sheet" />
            </div>
          </Container>
        </Popover>
      ) : null}
    </>
  );
};

export default ViewSubStagePopover;

function SubListItem({ label, icon }) {
  return (
    <div className="sub-stage__item">
      <div className="sub-stage__item--start">
        <div className="sub-stage__item--text">{label}</div>
      </div>
      {icon ? <div className="sub-stage__item--end"></div> : null}
    </div>
  );
}
