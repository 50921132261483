import React from "react";
import FieldControl from "../FieldControl";
// import { TimeField } from '@mui/x-date-pickers/TimeField';

const TimePickerField = (props, ref) => {
  const {
    inputOuterClass,
    className,
    size = "sm",
    startIcon = null,
    value,
    disabled,
    ...other
  } = props;

  // const onChangeHandler = (e) => {
  //   const keyCode = e.keyCode;
  //   console.log(e);
  //   const input = e.target;

  //   // if (keyCode === 8) return;
  //   console.log(keyCode);
  //   console.log(input.selectionStart, input.selectionEnd);
  // };

  return (
    <FieldControl
      className={inputOuterClass}
      size={size}
      startIcon={startIcon}
      hasStartIcon={startIcon !== null}
      isDisabled={disabled}
    >
      {/* <TimeField slot={{textField: <input />}}  /> */}
      <input
        ref={ref}
        type="time"
        disabled={disabled}
        value={value}
        // onKeyDown={onChangeHandler}
        {...other}
      />
    </FieldControl>
  );
};

export default React.forwardRef(TimePickerField);
