import React from "react";

import BaseDashboardBlock from "../../common/components/BaseDashboardBlock";
import ProjectItemWidget from "../../common/components/Widgets/ProjectWidget";
import TimeLineWidget from "../../common/components/Widgets/TimeLineWidget";
import WelcomeWidget from "../../common/components/Widgets/WelcomeWidget";
import NotesWidget from "../../common/components/Widgets/NotesWidget";
import { Container, Outer } from "../../styled/dashboard.styled";
import EventCalendar from "./../../../../common/components/UI/EventCalendar/index";
import ThingsTodoWidget from "../../common/components/Widgets/ThingsTodoWidget";

const notes = [
  "Check availability of DP",
  "New Idea Tibet Meditation discuss with steve after ...",
  "Equipment rental price list",
  "Check availability of DP",
  "New Idea Tibet Meditation discuss with steve after ...",
  "Equipment rental price list",
  "Check availability of DP",
  "New Idea Tibet Meditation discuss with steve after ...",
  "Equipment rental price list",
];

function AppDashboard() {
  console.log("app dashboard");
  return (
    <Outer>
      {/* 
      -------------------------
      Welcome widget
      -------------------------
      */}

      <WelcomeWidget />

      <Container className="app-dashboard">
        <div className="dashboard_wrapper pt-0">
          <div className="dashboard_widgets">
            {/* 
            -------------------------
            Notes widget
            -------------------------
            */}
            <NotesWidget notes={notes.slice(0, 6)} />

            {/* 
              -------------------------
              Project Widget
              -------------------------
            */}
            <ProjectItemWidget />

            {/* 
            -------------------------
            Things to-do widget
            -------------------------
            */}
            <BaseDashboardBlock title="Things to-do" blockClassName="todo">
              {/* Things todo */}
              <ThingsTodoWidget />
            </BaseDashboardBlock>

            {/* 
            -------------------------
            My Calendar
            -------------------------
            */}
            <BaseDashboardBlock
              title="My Calendar"
              blockClassName="my_calendar"
            >
              <EventCalendar hideMainNote />
            </BaseDashboardBlock>
          </div>
        </div>
      </Container>
    </Outer>
  );
}

export default AppDashboard;
