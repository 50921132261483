import React from "react";

import FormOne from "./Forms/FormOne";
import FormTwo from "./Forms/FormTwo";
import FormThree from "./Forms/FormThree";

import IconArrowRight from "../../../../common/components/icons/Line/arrow-right";
import IconArrowLeft from "../../../../common/components/icons/Line/arrow-left";
import UIButton from "../../../../common/components/Buttons/UIButton";

import {
  getCurrentStep,
  onBeforeNext,
  onNext,
  onPrevious,
  onSkip,
  onToggleEditMode,
  useScheduleWizardStore,
} from "./store";
import { ScheduleWizardModal } from "./styled";

const ScheduleWizard = () => {
  const isVisible = useScheduleWizardStore((state) => state.isVisible);
  const steps = useScheduleWizardStore((state) => state.steps);
  const activeStep = useScheduleWizardStore(
    (state) => state.currentActiveStepKey
  );
  const currentStep = getCurrentStep();

  const handleSubmit = React.useCallback((value, options) => {
    console.log("Done", value, options);
    onNext();
  }, []);

  const renderSteps = React.useMemo(() => {
    if (!activeStep) return null;

    const mode = currentStep?.isEditMode ? "edit" : "";

    switch (activeStep) {
      case "step_01": {
        return <FormOne mode={mode} />;
      }
      case "step_02": {
        return <FormTwo mode={mode} />;
      }
      case "step_03": {
        return <FormThree mode={mode} />;
      }

      default:
        return null;
    }
  }, [activeStep, currentStep]);

  return (
    <ScheduleWizardModal
      steps={steps.filter((step) => step.isEnabled)}
      visibility={isVisible}
      title={currentStep?.modalTitle}
      bodyTitle={currentStep?.title}
      bodySubTitle={currentStep?.subTitle}
      handleEdit={onToggleEditMode}
      showEditButton={currentStep?.showEditButton}
      className={currentStep?.options?.modalClassName}
      bodyFooterSlots={{
        leftComponent: FooterLeftButtons,
        rightComponent: () => <FooterRightButtons />,
      }}
    >
      {renderSteps}
    </ScheduleWizardModal>
  );
};

function FooterLeftButtons() {
  const actionStatus = useScheduleWizardStore((state) => state.actionStatus);

  return (
    <>
      {/* {actionStatus?.hasSkipAndFinish ? (
        <UIButton
          variant={UIButton.Variant.Success}
          size={UIButton.Sizes.Small}
        >
          Skip & Finish Setup
        </UIButton>
      ) : null} */}

      {actionStatus?.hasPrev ? (
        <UIButton
          variant={UIButton.Variant.Secondary}
          size={UIButton.Sizes.Small}
          startIcon={<IconArrowLeft />}
          onClick={onPrevious}
        >
          Previous
        </UIButton>
      ) : null}
    </>
  );
}

function FooterRightButtons() {
  const actionStatus = useScheduleWizardStore((state) => state.actionStatus);

  return (
    <>
      {actionStatus?.hasSkip ? (
        <UIButton
          variant={UIButton.Variant.Secondary}
          size={UIButton.Sizes.Small}
          onClick={onSkip}
        >
          Skip
        </UIButton>
      ) : null}

      {actionStatus?.hasNext ? (
        <UIButton
          variant={UIButton.Variant.Primary}
          size={UIButton.Sizes.Small}
          onClick={onNext}
          endIcon={<IconArrowRight />}
          // onClick={onBeforeNext}
        >
          Next
        </UIButton>
      ) : null}

      {actionStatus?.hasFinish ? (
        <UIButton
          variant={UIButton.Variant.Primary}
          size={UIButton.Sizes.Small}
        >
          Finish Setup
        </UIButton>
      ) : null}
    </>
  );
}

export default ScheduleWizard;
