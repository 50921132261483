import React from "react";

import * as Styled from "./styled";
import useFiles from "../../../../hooks/useFiles";
import { IconBlock } from "../../../../styled/Common/Icons";
import { Image } from "../../icons/Line";
import { uploadImage } from "../../../../Actions/Common";
import Typography from "../../Typography";
import AnimateLoader from "../../AnimateLoader";

export const ConfirmationTypes = {
  Confirm: 1,
  Cancel: 0,
};

const FileDropZone = ({
  outerClassName,
  name = "file__input",
  allowTypes = ["image/png", "image/jpeg"],
  allowedTypesText = "SVG, PNG, JPG, GIF (max 800px x 800px)",
  onFileUpload,
  autoServerUpload = false,
  containerName,
  disabled,
  onUploadWithConfirmation,
}) => {
  const inputRef = React.useRef(null);
  const [isUploading, setIsUploading] = React.useState(false);
  const [progress, setProgress] = React.useState(undefined);

  const onServerUpload = React.useCallback(
    async (files = []) => {
      if (disabled) return;

      if (!files || files.length === 0) return;

      const fileData = files?.map((f) => f.fileData);
      const fileName = files?.map((f) => f.file?.name);
      const fileType = files?.map((f) => f.file?.type);

      const file = {
        containerName: containerName
          ? containerName
          : process.env.REACT_APP_DOCUMENTS_CONTAINER,
        imageData: fileData,
        imageName: fileName,
        imageType: fileType,
      };

      try {
        const config = {
          onUploadProgress: function (progressEvent) {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            setProgress(percentCompleted);
          },
        };

        setIsUploading(true);
        const uploadRes = await uploadImage(file, config);

        if (uploadRes.isSuccess) {
          const filePaths = uploadRes?.result.map((f) => f?.filePath);
          onFileUpload && onFileUpload(uploadRes?.result, filePaths);
        }
      } catch (error) {
        setIsUploading(false);
        setProgress(undefined);
        console.error("File Upload ", error);
      }
    },
    [containerName, disabled, onFileUpload]
  );

  React.useEffect(() => {
    if (progress === 100) {
      let time = setTimeout(() => {
        setIsUploading(false);
        setProgress(undefined);
      }, 500);

      return () => {
        clearTimeout(time);
      };
    }
  }, [progress]);

  const onUpload = (files) => {
    if (disabled) return;

    if (!autoServerUpload) {
      onFileUpload && onFileUpload(files);
      return;
    }

    onServerUpload(files);
  };

  const uploader = useFiles({
    name: name,
    onFileUpload: onUpload,
    allowTypes,
    isUploading,
  });

  const {
    // uploadedFiles,
    error,
    handleChange,
    handleDrag,
    handleDrop,
    isDragOver,
    isDragStart,
    isDrop,
  } = uploader;

  const onButtonClick = () => {
    if (disabled || isUploading) return;

    inputRef.current.click();
  };

  return (
    <Styled.UploaderOuter className={outerClassName}>
      <Styled.Uploader
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        isDragStart={isDragStart}
        isDragOver={isDragOver}
        isDrop={isDrop}
        onClick={onButtonClick}
        isDisabled={disabled}
        isUploading={isUploading}
      >
        {/* Loading indicator */}
        {isUploading ? (
          <div className="loader">
            {/* <AnimateLoader /> */}
            <div className="text">Uploading...</div>
            {progress ? (
              <div className="progress__bar">
                <div
                  className="progress__value"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="icon__wrapper">
          <IconBlock className="icon__block">
            <Image />
          </IconBlock>
        </div>

        <Typography
          className="text"
          variant={Typography.font_variant.xSmall}
          fontWeight={Typography.font_weight.bold}
        >
          Click to upload&nbsp;
          <Typography
            as="span"
            variant={Typography.font_variant.xSmall}
            fontWeight={Typography.font_weight.regular}
            className="text__black"
          >
            or drag and drop
          </Typography>
        </Typography>

        <Typography
          className="text text__file__types"
          variant={Typography.font_variant.xSmall}
          fontWeight={Typography.font_weight.regular}
        >
          {allowedTypesText}
        </Typography>

        <input
          ref={inputRef}
          className="file__input"
          type="file"
          name={name}
          onChange={handleChange}
          accept={allowTypes.join(",")}
          disabled={disabled}
        />
      </Styled.Uploader>

      {error ? <Styled.Error>{error}</Styled.Error> : null}

      <div className="preview__files"></div>
    </Styled.UploaderOuter>
  );
};

export default FileDropZone;
