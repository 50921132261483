import { Link } from "react-router-dom";
import styled from "styled-components";

export const LogoWrapper = styled(Link)`
  display: inline-flex;
  height: var(--layout-size-16);
  justify-content: center;
  align-items: center;
  
  .logo_img {
    max-height: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
