import React from "react";
import ReactSelect from "react-select";
import { ArrowDown } from "../../icons/Line";
import FillArrowDown from "../../icons/Fill/arrow-down";
import * as CloseIcons from "../../icons/Line/Close";
import FieldControl from "../FieldControl";

const DropdownIndicator = (props) => {
  const { getStyles, isArrowFilled } = props;
  return (
    <div style={getStyles("dropdownIndicator", props)}>
      {isArrowFilled ? <FillArrowDown /> : <ArrowDown />}
    </div>
  );
};

const ClearIndicator = (props) => {
  const {
    children = <CloseIcons.Svg01 />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const SelectField = ({
  isValid,
  isError,
  size = "sm",
  children,
  options,
  isDisabled,
  menuPortalTarget = document.body,
  inputOuterClass,
  isArrowFilled = false,
  ...other
}) => {
  return (
    <FieldControl
      size={size}
      isError={isError}
      isValid={isValid}
      isDisabled={isDisabled}
      inputOuterClass={inputOuterClass}
    >
      <ReactSelect
        options={options}
        classNamePrefix="sr"
        components={{
          DropdownIndicator: (props) => (
            <DropdownIndicator isArrowFilled={isArrowFilled} {...props} />
          ),
          ClearIndicator,
        }}
        styles={{
          container: (style) => ({ ...style, width: "100%" }),
          control: (style) => ({
            ...style,
            borderRadius: `var(--layout-border-radius-4)`,
            backgroundColor: isDisabled ? "var(--color-neutral-30)" : "#fff",
            border: "none",
            // height: "30px",
            minHeight: "inherit",
            boxShadow: "none",
            width: "100%",
            padding: "2px var(--layout-spacing-8)",
            fontSize: "var(--small-regular-font-size)",
          }),
          valueContainer: (style) => ({ ...style, padding: 0 }),
          indicatorsContainer: (style) => ({ ...style, padding: 0 }),
          dropdownIndicator: (style) => ({ ...style, padding: 0 }),
          clearIndicator: (style) => ({
            ...style,
            padding: 0,
            fontSize: "12px",
            marginRight: "8px",
          }),
          indicatorSeparator: (style) => ({ ...style, display: "none" }),
          singleValue: (style) => ({ ...style, marginLeft: 0 }),
          menuPortal: (style) => ({ ...style, zIndex: 1561 }),
        }}
        isDisabled={isDisabled}
        menuPortalTarget={menuPortalTarget}
        menuPosition={"fixed"}
        closeMenuOnScroll={(e) => {
          return !e?.target?.classList?.contains("sr__menu-list");
        }}
        {...other}
      />
    </FieldControl>
  );
};

export default SelectField;
