import { ProjectRoutePath } from "./projects-path";
import { SettingRoutePath } from "./settings-path";
import { TemplateRoutePath } from "./templates-path";
import { CrewRoutePath } from "./crew-paths";
import { PublicRoutePath } from "./public-path";
import { DashboardPaths } from "../../pages/Dashboard/routes/index";
import { BudgetPaths } from "../../pages/Budget/routes/index";
import { TemplatePaths } from "../../pages/Template/routes/index";
import { SettingRoutePaths } from "../../pages/Setting/routes/index";
import { OnboardingPaths } from "../../pages/Onboarding/routes";

export const ROUTES_PATH = {
  DashboardPaths,
  PublicRoutePath,
  ProjectRoutePath,
  SettingRoutePath,
  TemplateRoutePath,
  CrewRoutePath,
  BudgetPaths,
  // New
  TemplatePaths,
  // New setting routes paths
  SettingRoutePaths,
  OnboardingPaths,
};
