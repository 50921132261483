import React from "react";
import BaseDashboardBlock from "../../BaseDashboardBlock";

const NotesWidget = ({ notes }) => {
  return (
    <BaseDashboardBlock
      title="My Notes"
      onAddNew={() => {}}
      blockSpan={2}
      blockContentClassName="notes"
    >
      {notes.map((note, i) => {
        return (
          <div className="note" key={i}>
            {note}
          </div>
        );
      })}
    </BaseDashboardBlock>
  );
};

export default NotesWidget;
