import React from "react";

import TanStackTable, {
  columnHelper,
  Elements,
} from "../../../../../../../../components/common/TanStackTable";
import { useSettingContext } from "../../../../../../../../shared/context/SettingContext";
import { ViewButtonWrapper } from "../../../../styled";
import ViewSubStagePopover from "../../../../components/ui/ViewSubStagePopover";
import { FlexCenter } from "../../../../../../../../styled";
import { useActivityBlock } from "../../../context/ActivityBlockContext";

const data = [
  {
    name: "Lorem ipsum",
    subStages: "Sb/394",
    id: "34",
  },
];

function StagesTable() {
  const { projectId, tenantId } = useSettingContext();
  const { StageModal } = useActivityBlock();

  const columns = [
    columnHelper.accessor("name", {
      header: "Production Stages",
      size: 300,
      cell: ({ row, getValue }) => {
        return getValue();
      },
    }),

    columnHelper.accessor("subStages", {
      header: () => {
        return <FlexCenter>Sub Stages</FlexCenter>;
      },
      size: 50,
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <FlexCenter>
            <ViewSubStagePopover data={getValue()} />
          </FlexCenter>
        );
      },
    }),

    columnHelper.accessor("id", {
      header: "Action",
      size: 30,
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <Elements.Actions gap={8}>
            <Elements.ActionButton
              type="edit"
              title="Edit"
              onClick={() => {
                StageModal.open({
                  type: "EDIT",
                  id: 12,
                });
              }}
            />
            <Elements.ActionButton type="delete" />
            {/* <Elements.ActionButton type="duplicate" /> */}
          </Elements.Actions>
        );
      },
    }),
  ];

  return (
    <>
      <TanStackTable
        enabledRowsSpace
        isLoading={false}
        columns={columns}
        data={data}
      />
      {/* Dialogs */}
    </>
  );
}

export default StagesTable;
