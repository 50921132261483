import React from "react";
import produce from "immer";

import { IconBlock } from "../../../../../styled/Common/Icons";
import { useFileManager } from "../context/FileManagerContext";
import InputField from "../../InputField";
import SelectField from "../../SelectField";
import DeleteIcon from "../../../icons/Line/Delete/01";
import EditIcon from "../../../icons/Line/Edit/01";
import * as LineIcon from "../../../icons/Line/";
import * as FileTypeIcon from "../../../icons/FileTypes/Fill";
import * as Styled from "../styled";
import Alert from "../../../../../utility/alert";

function FileListItem(props) {
  const {
    fileName,
    sectionValue,
    mode = "view",
    isSorted = false,
    localId = null,
  } = props;

  const { onDelete, onSave, onEdit, sections, value } = useFileManager();

  const [formValues, setFormValues] = React.useState({
    fileName: fileName,
    sectionValue: sectionValue,
  });

  const [error, setError] = React.useState("");

  const onNameChangeHandler = React.useCallback((e) => {
    const val = e.target?.value || "";

    setFormValues(
      produce((draft) => {
        draft.fileName = val;
      })
    );
  }, []);

  const onSectionChangeHandler = React.useCallback((selected) => {
    setFormValues(
      produce((draft) => {
        draft.sectionValue = selected.value;
      })
    );
  }, []);

  const handleSave = React.useCallback(
    ({ localId, formValues }) => {
      if (!formValues.fileName.trim()) {
        setError("File name is required");
        return;
      }

      const sectionData = value.filter(
        (value) => value.sectionValue === formValues.sectionValue
      );

      const isNameExist = sectionData.find(
        (val) =>
          val.fileName?.trim().toLowerCase() ===
          formValues.fileName?.trim().toLowerCase()
      );

      if (isNameExist && isNameExist.localId !== localId) {
        setError("File name already exists");
        return false;
      }

      setError("");

      const formData = {
        ...formValues,
        fileName: formValues?.fileName.trim(),
      };

      onSave({ localId, formValues: formData });
    },
    [onSave, value]
  );

  const handleDelete = React.useCallback(
    (localId) => {
      Alert.confirm({
        title: "Are you sure?",
        text: "You want to delete this file",
        confirmButtonText: "Yes, Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          onDelete(localId);
        }
      });
    },
    [onDelete]
  );

  return (
    <Styled.FileItem
      className="item"
      hasError={error.length > 0 ? true : false}
    >
      <div className="start">
        <div className="file__name">
          <IconBlock className="icon">
            <FileTypeIcon.Doc />
          </IconBlock>

          {mode === "edit" ? (
            <InputField
              inputOuterClass="input__wrapper"
              value={formValues.fileName}
              onChange={onNameChangeHandler}
              onFocus={(e) => e.target.select()}
            />
          ) : (
            <div className="file__name__text" title={fileName}>
              {fileName}
            </div>
          )}
        </div>

        {mode === "edit" || !isSorted ? (
          <div className="section__selector">
            <SelectField
              options={sections}
              value={sections.find(
                (sec) => sec.value === formValues.sectionValue
              )}
              defaultValue={"Select"}
              menuPortalTarget={null}
              onChange={onSectionChangeHandler}
            />
          </div>
        ) : null}
      </div>

      <div className="end">
        {onEdit && isSorted && mode === "view" ? (
          <Styled.ActionButton
            title="Edit"
            onClick={() => onEdit(localId)}
            type="button"
          >
            <IconBlock className="icon">
              <EditIcon />
            </IconBlock>
          </Styled.ActionButton>
        ) : null}

        {(onSave && mode === "edit") || !isSorted ? (
          <Styled.ActionButton
            disabled={!formValues.fileName || !formValues.sectionValue}
            title="Save"
            onClick={() => handleSave({ localId, formValues })}
            type="button"
          >
            <IconBlock className="icon">
              <LineIcon.Save />
            </IconBlock>
          </Styled.ActionButton>
        ) : null}

        {onDelete ? (
          <Styled.ActionButton
            title="Delete"
            onClick={() => handleDelete(localId)}
            type="button"
          >
            <IconBlock className="icon">
              <DeleteIcon />
            </IconBlock>
          </Styled.ActionButton>
        ) : null}
      </div>

      {error.length > 0 ? <span className="error">{error}</span> : null}
    </Styled.FileItem>
  );
}

export default FileListItem;
