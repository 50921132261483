import React from "react";
import FileListItem from "../FileListItem";
import * as Styled from "./styled";

const UploadSection = ({ title, list = [] }) => {
  
  if (list && list.length === 0) {
    return null;
  }

  return (
    <>
      <Styled.Section>
        <div className="__title">{title}</div>
        <div className="__items">
          <ul className="file__list">
            {list.map((file, i) => {
              return (
                <FileListItem
                  key={i}
                  fileName={file?.fileName}
                  sectionValue={file?.sectionValue}
                  localId={file.localId}
                  mode={file.mode}
                  isSorted={file.isSorted}
                />
              );
            })}
          </ul>
        </div>
      </Styled.Section>
    </>
  );
};

export default UploadSection;

// <div className="empty__content">
//   <div className="text">
//     Any <b>{title}</b> file not uploaded yet.
//   </div>
// </div>
