import React from "react";
import "./styles.scss";

import doc from "../../../data/toolbar_customization.json";

import {
  DocumentEditorContainerComponent,
  DocumentEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-documenteditor";
import useUserData from "../../../hooks/useUserData";

const hostUrl = "https://ej2services.syncfusion.com/production/web-services/";
export const Enforce_Protection_Password = "loop_d947cb8c";

export const ToolbarItems = {
  New: "New",
  Open: "Open",
  Separator: "Separator",
  Undo: "Undo",
  Redo: "Redo",
  Image: "Image",
  Table: "Table",
  Hyperlink: "Hyperlink",
  Bookmark: "Bookmark",
  TableOfContents: "TableOfContents",
  Header: "Header",
  Footer: "Footer",
  PageSetup: "PageSetup",
  PageNumber: "PageNumber",
  Break: "Break",
  // InsertFootnote: "InsertFootnote",
  // InsertEndnote: "InsertEndnote",
  Find: "Find",
  Comments: "Comments",
  TrackChanges: "TrackChanges",
  LocalClipboard: "LocalClipboard",
  RestrictEditing: "RestrictEditing",
  FormFields: "FormFields",
  UpdateFields: "UpdateFields",
};

export const EnforceProtectionTypes = {
  NoProtection: "NoProtection",
  ReadOnly: "ReadOnly",
  FormFieldsOnly: "FormFieldsOnly",
  CommentsOnly: "CommentsOnly",
};

DocumentEditorComponent.Inject(Toolbar);

const defaultToolbarItems = Object.keys(ToolbarItems);

const DocumentView = (props, ref) => {
  const containerRef = React.useRef();
  const [isCreated, setIsCreated] = React.useState(false);
  const { user } = useUserData();

  const displayName = user?.result?.displayName;

  const {
    containerId = "containerBody",
    enableToolbar = false,
    toolbarItems = defaultToolbarItems,
    sfdtData = null,
    onDocumentCreated = (documentEditorRef) => {},
  } = props;

  const onSaveAsDocx = React.useCallback(({documentName='loop_doc'}) => {
    const container = containerRef?.current;

    return new Promise((resolve, reject) => {
      container.documentEditor.saveAsBlob("Docx").then((blob) => {
        const fileReader = new FileReader();

        fileReader.onload = function () {
          const base64Text = ";base64,";

          const documentData = {
            fileName: documentName + ".docx",
            documentData: fileReader.result.substring(
              fileReader.result.indexOf(base64Text) + base64Text.length
            ),
          };

          resolve(documentData);
        };

        fileReader.onerror = (er) => {
          reject(er);
        };

        fileReader.readAsDataURL(blob);
      });
    });
  }, []);

  React.useImperativeHandle(ref, () => ({
    onSaveAsDocx: onSaveAsDocx,
  }));

  const onLoadDefault = React.useCallback(() => {
    // console.log("document ", containerRef?.current);
    // console.log("documentEditor ", containerRef?.current?.documentEditor);
    const container = containerRef?.current;

    container.pageOutline = "#fff";
    container.serviceUrl = hostUrl + "api/documenteditor/";

    // containerRef.current.documentEditor.showComments = true;

    // containerRef.current.restrictEditing = true;
    // containerRef.current.isReadOnly = true;
    // containerRef.current.documentEditor.editor.enforceProtection('123','CommentsOnly');
    // containerRef.current.documentEditor.editor.stopProtection('123');
    // containerRef.current.enablePrint = true;

    // containerRef.current.showPropertiesPane = false;
    containerRef.current.documentEditor.currentUser = displayName;
    // container.enableContextMenu = true;
    // container.isReadOnly = true;
    // container.documentEditor.showComments = true;
    if (sfdtData) {
      container.documentEditor.open(sfdtData);
    }

    // container.restrictEditing = true;

    //enforce protection
    // container.documentEditor.editor.enforceProtection("123", "CommentsOnly");
    //stop the document protection
    // container.documentEditor.editor.stopProtection("123");
    // containerRef.current.documentEditor.editor.stopProtection(
    //   Enforce_Protection_Key
    // );
  }, [sfdtData, displayName]);
  // enableToolbar, toolbarItems

  const onResize = React.useCallback(() => {
    if (
      containerId &&
      document.getElementById(containerId) &&
      containerRef.current
    ) {
      const wrapperHeight = document.getElementById(containerId).clientHeight;
      containerRef.current.resize("100%", wrapperHeight);
    }
  }, [containerId]);

  const onCreated = () => {
    // onLoadDefault();
    onResize();
    setIsCreated(true);
  };

  React.useEffect(() => {
    onLoadDefault();
  }, [onLoadDefault]);

  React.useEffect(() => {
    if (isCreated) {
      onDocumentCreated && onDocumentCreated(containerRef?.current);
    }
  }, [onDocumentCreated, isCreated]);

  React.useEffect(() => {
    const container = containerRef?.current;

    if (toolbarItems.length > 0) {
      setTimeout(() => {
        container.enableToolbar = true;
        container.toolbarItems = toolbarItems;
      });
    }
  }, [toolbarItems]);

  React.useEffect(() => {
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  return (
    <div className="document">
      <DocumentEditorContainerComponent
        id="containerRef"
        ref={(scope) => {
          containerRef.current = scope;
        }}
        created={onCreated}
      />
    </div>
  );
};

export default React.forwardRef(DocumentView);