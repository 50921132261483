import React from "react";
import { OverlayTrigger } from "react-bootstrap";

import UIPopover from "../../../../../ui-components/Popover";
import useUserData from "../../../../../../hooks/useUserData";
import { IconBlock } from "../../../../../../styled/Common/Icons";
import IconLogout from "../../../../../../common/components/icons/Line/Logout/01";
import IconEdit from "../../../../../../common/components/icons/Line/Edit/01";
import Avatar from "../../../../../../common/components/UI/Avatar";
// import SettingIcon from "../../../../../assets/svg/setting.svg";
import IconSetting from "../../../../../../common/components/icons/Fill/settings";

import "./styles.scss";
import IconButton from "../../../../../../common/components/Buttons/IconButton";
import { ROUTES_PATH } from "../../../../../../routes/paths";
import { generatePath, useNavigate } from "react-router-dom";

const UserSettingOption = ({
  onLogout,
  onTenantSelect,
  selectedTenantId,
  tenantList = [],
  tenantSettingsRoute,
}) => {
  const { user } = useUserData();
  const navigate = useNavigate();

  const onTenantSelectHandler = (id = 0) => {
    onTenantSelect && onTenantSelect(id);
  };

  const goToTenantSettings = React.useCallback(
    (id) => {
      const path = generatePath(tenantSettingsRoute?.path, { id });

      if (selectedTenantId !== id) {
        onTenantSelect(id, path);
        return;
      }

      navigate(path);
    },
    [navigate, onTenantSelect, selectedTenantId, tenantSettingsRoute?.path]
  );

  const goToProfile = React.useCallback(() => {
    const main = ROUTES_PATH.SettingRoutePath.path;
    const profile = ROUTES_PATH.SettingRoutePath.MyProfile.path;
    const path = generatePath(`${main}/${profile}`);

    navigate(path);
  }, [navigate]);

  const popover = (
    <UIPopover id="setting__options">
      <div className="user_info">
        <div className="start">
          <div className="name">{user.result.givenName}</div>
          <div className="email">{user.result.email}</div>
        </div>

        <div className="end">
          <IconButton
            className="btn__user__edit"
            icon={<IconEdit />}
            btnSize={32}
            btnIconSize={16}
            data-tooltip-text="Edit User Profile"
            data-tooltip-placement="right"
            onClick={() => goToProfile()}
          />
        </div>
      </div>

      {tenantList.length > 0 ? (
        <>
          <div className="line"></div>

          <div className="tenet-list">
            <div className="heading">Switch Tenant</div>
            <ul>
              {tenantList.map((t, i) => {
                return (
                  <li key={i}>
                    <div
                      className={[
                        selectedTenantId === t.tenantId ? "active" : "",
                        "item__inner",
                      ].join(" ")}
                    >
                      <span onClick={() => onTenantSelectHandler(t?.tenantId)}>
                        {t?.tenantName}
                      </span>

                      {tenantSettingsRoute?.path ? (
                        <IconButton
                          className="btn__tenant__setting"
                          icon={<IconSetting />}
                          btnSize={32}
                          btnIconSize={20}
                          data-tooltip-text="Tenant Settings"
                          data-tooltip-placement="right"
                          onClick={() => goToTenantSettings(t.tenantId)}
                        />
                      ) : null}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      ) : null}

      {/* <div className="line"></div> */}

      {/* <div className="actions">
        <Button variant="secondary" onClick={onLogout}>
          Log out
        </Button>
      </div> */}
    </UIPopover>
  );

  return (
    <div className="user__setting">
      <OverlayTrigger
        rootClose
        trigger="click"
        placement="right-start"
        overlay={popover}
      >
        <div
          className="avatar__wr"
          data-tooltip-text="User Profile"
          data-tooltip-placement="right"
          id="user-profile"
        >
          <Avatar
            size="size-26"
            src="https://www.w3schools.com/w3images/avatar2.png"
          />
        </div>
      </OverlayTrigger>

      <div className="line"></div>

      <button
        className="btn__logout"
        data-tooltip-text="Logout"
        data-tooltip-placement="right"
        onClick={onLogout}
      >
        <IconBlock>
          <IconLogout />
        </IconBlock>
        {/* <img src={SettingIcon} alt="" /> */}
      </button>
    </div>
  );
};

export default UserSettingOption;
