import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import * as Styled from "./styled";
import FieldGroup from "../../../../../../../../../../../common/components/Form/FieldGroup";
import InputField from "../../../../../../../../../../../common/components/Form/InputField";
import Button from "../../../../../../../../../../../common/components/Buttons/UIButton";
import SubStageList from "./SubStageList";
import { useActivityBlock } from "../../../../context/ActivityBlockContext";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  subStages: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .min(1, "Minimum 1")
    .required("Name is required"),
});

const initialValues = {
  name: "",
  subStages: [],
};

function Form({ onSubmit, formData }) {
  const { stageModalData, StageModal } = useActivityBlock();

  const isEditMode = stageModalData?.payload?.type === "EDIT";

  /**
   *
   */
  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values, actions) => {
      const val = {
        ...values,
      };

      onSubmit(val, actions);
    },
  });

  const { errors, touched, values, handleChange, handleSubmit, setFieldValue } =
    formik;

  return (
    <Styled.Form onSubmit={handleSubmit}>
      <FieldGroup label="Name " error={touched.name && errors.name}>
        <InputField
          placeholder="Enter Stage Name "
          value={values.name}
          name="name"
          onChange={handleChange}
        />
      </FieldGroup>

      <FieldGroup
        label="Add Sub Stage"
        error={touched.subStages && errors.subStages}
      >
        <SubStageList
          value={values.subStages}
          onChange={(val) => {
            setFieldValue("subStages", val);
          }}
        />
      </FieldGroup>

      <FieldGroup className="actions">
        <Button
          type="button"
          variant={Button.Variant.Danger}
          size={Button.Sizes.Small}
          className="cancel__btn"
          onClick={() => {
            StageModal.close();
          }}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          variant={Button.Variant.Primary}
          size={Button.Sizes.Small}
        >
          {isEditMode ? "Save" : "Add"}
        </Button>
      </FieldGroup>
    </Styled.Form>
  );
}

export default Form;
