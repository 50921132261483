import React from "react";

// import FileDropZone from "../../../../../../../common/components/Form/FileDropZone";
import InputField from "../../../../../../../common/components/Form/InputField";
import SelectField from "../../../../../../../common/components/Form/SelectField";
import { FieldTypes } from "../../../../../../../constant/FieldTypes";
import TextAreaField from "../../../../../../../common/components/Form/TextAreaField";
import DatePickerField from "../../../../../../../common/components/Form/DatePickerField";
import TimePickerField from "../../../../../../../common/components/Form/TimePickerField";
import PasteButton from "../../../Form/PasteButton";
import ExportButton from "../../../Form/ExportButton";
// import InsertUrlList from "../../../../../../../common/components/Form/InsertUrlList";

import * as Styled from "../styled";
import { useTextSelectionStore } from "../../../../../store/TextSelectionStore";
import InsertUrlList from "../../../../../../../common/components/Form/InsertUrlList";
import FileUploadManager from "../../../../../../../common/components/Form/FileUploadManager";
import EventCalendar from "../../../../../../../common/components/UI/EventCalendar";
import GroupFields from "../../../Form/GroupFields";

const InputControl = ({
  field,
  values,
  handleChange,
  touched,
  errors,
  setFieldValue,
  tabId,
  setMainField,
}) => {
  const name = field.name();
  const isMainField = field.isMainField();
  const isDisabled = field.disabled();
  const type = String(field.fieldType().id());

  const copyText = useTextSelectionStore((state) => state.copyText);

  const isEnablePasteAndExport = useTextSelectionStore(
    (state) => state.enablePasteAndExportOptions
  );

  const handlePaste = React.useCallback(
    (name, value) => {
      if (name) {
        setFieldValue(name, value);
      }
    },
    [setFieldValue]
  );

  const blockLayout = React.useCallback(
    (
      variant = "vertical",
      name,
      input,
      option = { canPaste: false, canExport: false }
    ) => {
      const pasteAndExport = (
        <>
          {name &&
          option?.canPaste &&
          !isDisabled &&
          copyText &&
          isEnablePasteAndExport ? (
            <PasteButton name={name} onPaste={handlePaste} />
          ) : null}

          {/* Export button */}
          {name &&
          option?.canExport &&
          // values[name].length > 0 &&
          isEnablePasteAndExport ? (
            <ExportButton name={name} value={values[name] || ""} />
          ) : null}
        </>
      );

      if (variant === "vertical") {
        return (
          <Styled.UIFieldGroup
            variant="vertical"
            label={field.label()}
            error={touched[name] && errors[name]}
            // hint={field.getHint()}
          >
            {input}
            {pasteAndExport}
          </Styled.UIFieldGroup>
        );
      }

      return (
        <Styled.UIFieldGroup
          variant="horizontal"
          colSizes={["50%", "50%"]}
          label={field.label()}
          error={touched[name] && errors[name]}
          className={field.isMainField() ? "main-field-wrapper" : ""}
          // hint={field.getHint()}
        >
          {input}
          {pasteAndExport}
        </Styled.UIFieldGroup>
      );
    },
    [
      isDisabled,
      copyText,
      isEnablePasteAndExport,
      handlePaste,
      values,
      field,
      touched,
      errors,
    ]
  );

  const controller = React.useMemo(() => {
    let inputElement;

    const textInput = () => {
      return blockLayout(
        "horizontal",
        name,
        <InputField
          ref={(ref) => {
            if (isMainField) {
              setMainField && setMainField({ ref, val: values[name], name });
            }
          }}
          type="text"
          name={name}
          value={values[name] || ""}
          onChange={handleChange}
          disabled={isDisabled}
          className={isMainField ? "is-main-field" : ""}
        />,
        { canPaste: true, canExport: true }
      );
    };

    const textArea = () => {
      return blockLayout(
        "vertical",
        name,
        <TextAreaField
          name={name}
          value={values[name] || ""}
          onChange={handleChange}
          disabled={isDisabled}
        />,
        { canPaste: true, canExport: true }
      );
    };

    switch (String(type)) {
      // Text input
      case String(FieldTypes.TextInput): {
        inputElement = textInput();
        break;
      }

      // Address
      case String(FieldTypes.Address): {
        inputElement = textArea();
        break;
      }

      // Date field
      case String(FieldTypes.Date): {
        inputElement = blockLayout(
          "horizontal",
          name,
          <DatePickerField
            name={name}
            value={values[name] || ""}
            onChange={handleChange}
            disabled={isDisabled}
          />
        );
        break;
      }

      // Time picker
      case String(FieldTypes.Time): {
        inputElement = blockLayout(
          "horizontal",
          name,
          <TimePickerField
            name={name}
            value={values[name] || ""}
            onChange={handleChange}
            disabled={isDisabled}
          />
        );
        break;
      }

      // Duration
      case String(FieldTypes.Duration): {
        inputElement = blockLayout(
          "horizontal",
          name,
          <InputField
            name={name}
            value={values[name] || ""}
            onChange={handleChange}
            disabled={isDisabled}
          />
        );
        break;
      }

      // TextArea
      case String(FieldTypes.TextArea): {
        inputElement = textArea();
        break;
      }

      // TextArea
      case String(FieldTypes.Reference): {
        inputElement = blockLayout(
          "horizontal",
          name,
          <InputField
            name={name}
            value={values[name] || ""}
            onChange={handleChange}
            disabled={isDisabled}
          />
        );
        break;
      }

      case String(FieldTypes.Currency): {
        inputElement = textInput();
        break;
      }

      case String(FieldTypes.Decimal): {
        inputElement = textInput();
        break;
      }

      case String(FieldTypes.Email): {
        inputElement = textInput();
        break;
      }

      case String(FieldTypes.Int): {
        inputElement = textInput();
        break;
      }

      case String(FieldTypes.Telephone): {
        inputElement = textInput();
        break;
      }

      // Select Input
      case String(FieldTypes.Select): {
        const options = field.fieldOptions();
        const isMulti = false; //field.fieldType().allowMultiple();

        const getValue = (val) => {
          if (options) {
            return isMulti
              ? options.filter(
                  (option) => val?.value?.indexOf(option.value) >= 0
                )
              : options.find((option) => option?.value === val?.value);
          }

          return isMulti ? [] : "";
        };

        const onChange = (option) => {
          if (isMulti) {
            setFieldValue(name, option ? option.map((item) => item.value) : []);
            return;
          }

          setFieldValue(name, option);
        };

        inputElement = blockLayout(
          "horizontal",
          name,
          <SelectField
            options={options}
            value={getValue(values[name])}
            onChange={onChange}
            isDisabled={isDisabled}
          />
        );

        break;
      }

      // File Input

      case String(FieldTypes.File): {
        // const onUploadFile = (result, filePaths) => {
        //   const existing = values[name] || [];
        //   // setFieldValue(name, filePaths[0] || "");

        //   setFieldValue(
        //     name,
        //     filePaths && filePaths.length > 0 ? [...existing, ...filePaths] : []
        //   );
        // };

        // inputElement = blockLayout(
        //   "vertical",
        //   name,
        //   <FileDropZone
        //     allowTypes={[
        //       "image/png",
        //       "image/jpeg",
        //       "application/pdf",
        //       "application/msword",
        //       "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        //     ]}
        //     autoServerUpload
        //     outerClassName="form__element"
        //     name={name}
        //     onFileUpload={onUploadFile}
        //     allowedTypesText="Upload any file"
        //     disabled={isDisabled}
        //   />
        // );

        inputElement = null;

        break;
      }

      case String(FieldTypes.FileUploadManager): {
        if (!field?.value()) {
          inputElement = null;
        }

        const { sections, value } = field?.value();

        const handleChange = ({
          sortedFilesBySection,
          unsortedFiles,
          value: changedValue,
        }) => {
          sortedFilesBySection &&
            sortedFilesBySection.forEach((data) => {
              setFieldValue(data?.value, data.files);
            });

          //
          setFieldValue(name, { ...values[name], value: changedValue });
        };

        inputElement = blockLayout(
          "vertical",
          name,
          <FileUploadManager
            uploadSections={sections}
            defaultValue={value}
            onChange={handleChange}
          />
        );

        break;
      }

      // URL List Input
      case String(FieldTypes.WebAddress): {
        inputElement = blockLayout(
          "vertical",
          name,
          <InsertUrlList
            outerClassName="form__element"
            value={values[name] || []}
            onChange={(data) => setFieldValue(name, data)}
          />
        );

        // inputElement = textInput();

        break;
      }

      case String(FieldTypes.Schedule): {
        inputElement = blockLayout(
          "vertical",
          name,
          <>
            {/* <ScheduleField
              buttonText="Schedule"
              isFullButton
              viewMode={false}
              name={name}
              value={values[name] || ""}
              onSubmit={(values) => {
                setFieldValue(name, values);
              }}
              disabled={isDisabled}
            /> */}

            <EventCalendar
              value={values[name] || {}}
              hideMainNote
              onSave={(values) => {
                setFieldValue(name, values);
              }}
            />
          </>
        );

        break;
      }

      case String(FieldTypes.GroupFields): {
        if (!field?.value()) {
          inputElement = null;
        }

        const { tabGroupId, fields } = field?.value();
        inputElement = blockLayout(
          "horizontal",
          name,
          <GroupFields
            key={tabGroupId}
            fields={fields}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            values={values}
            setMainField={setMainField}
          />
        );

        break;
      }

      default: {
        return null;
      }
    }

    return inputElement;
  }, [
    blockLayout,
    field,
    handleChange,
    isDisabled,
    isMainField,
    name,
    setFieldValue,
    setMainField,
    type,
    values,
  ]);

  return controller;
};

export default InputControl;
