import styled, { css } from "styled-components";

const verticalCss = css`
  grid-template-columns: 1;
  grid-template-rows: 0fr;
  grid-template-areas:
    "label"
    "control"
    "hint"
    "error";

  .label__wrapper {
    padding-bottom: var(--layout-spacing-8);
  }
`;

const horizontalCss = css`
  ${({ colRight = "auto", colLeft = "auto" }) => {
    return `
      --col-left: ${colLeft}; 
      --col-right: ${colRight};
    `;
  }}

  grid-template-columns:var(--col-left) var(--col-right);
  grid-template-areas:
    "label  control"
    ".  hint"
    ".  error";

  .label__wrapper {
    display: flex;
    align-items: center;
    padding-right: var(--layout-spacing-24);
  }
`;

export const FieldGroup = styled.div`
  --field-bottom-space: var(--layout-spacing-16);

  display: grid;

  &:not(:last-child) {
    margin-bottom: var(--field-bottom-space);
  }

  ${({ variant }) => {
    return variant === "horizontal" ? horizontalCss : verticalCss;
  }};

  .label__wrapper {
    grid-area: label;
  }

  .control__wrapper {
    grid-area: control;
  }

  .hint__wrapper {
    grid-area: hint;
    color: var(--font-tint-04);
    font-size: 10px;
    font-weight: 600;
    margin-top: 4px;
  }

  .error__wrapper {
    grid-area: error;
    color: var(--color-danger-200);
    font-size: 10px;
    font-weight: 600;
    margin-top: 4px;
  }
`;
