import React from "react";
import ReactDOM from "react-dom";
import { motion, AnimatePresence } from "framer-motion";
import * as Styled from "./styled";
import { IconBlock } from "../../../../../styled/Common/Icons";
import IconClose from "../../../icons/Line/Close/02";

const DraggableModal = (props, ref) => {
  const {
    minWidth = 590,
    maxHeight,
    visibility,
    onClose,
    children,
    header,
    modalHeaderClassName,
    modalCss,
    className,
    isLoading = false,
  } = props;

  const modalRef = React.useRef();

  const [status, setStatus] = React.useState({
    minWidth: minWidth,
    isDragging: false,
    maxHeight: 0,
    top: 0,
    left: 0,
    initialPosition: {
      x: 0,
      y: 0,
    },
  });

  const onMouseMove = React.useCallback(
    (e) => {
      const pos = status.initialPosition;

      if (status.isDragging) {
        setStatus((prev) => ({
          ...prev,
          left: e.pageX - pos.x,
          top: e.pageY - pos.y,
        }));
      }
      e.stopPropagation();
      e.preventDefault();
    },
    [status.initialPosition, status.isDragging]
  );

  const onMouseDown = React.useCallback((e) => {
    const pos = ReactDOM.findDOMNode(modalRef.current);

    if (pos) {
      setStatus((prev) => ({
        ...prev,
        isDragging: true,
        initialPosition: {
          x: e.pageX - pos.offsetLeft,
          y: e.pageY - pos.offsetTop,
        },
      }));
    }

    e.stopPropagation();
    e.preventDefault();
  }, []);

  const onMouseUp = React.useCallback(
    (e) => {
      document.removeEventListener("mousemove", onMouseMove);

      const modalEl = ReactDOM.findDOMNode(modalRef.current);

      if (!modalEl) return;

      const { x, y } = modalEl.getBoundingClientRect();

      const containerWidth = window.innerWidth;
      const containerHeight = window.innerHeight;

      let updatePos = {
        isDragging: false,
      };

      const maxOffsetLeft = modalEl.clientWidth * 0.15;
      const maxOffsetTop = modalEl.clientHeight * 0.1;

      if (y < 0) {
        updatePos.top = 0;
      } else if (x < 0) {
        updatePos.left = 0;
      } else if (x + maxOffsetLeft > containerWidth) {
        updatePos.left = containerWidth - maxOffsetLeft;
      } else if (y + maxOffsetTop > containerHeight) {
        updatePos.top = containerHeight - maxOffsetTop;
      }

      setStatus((prev) => ({
        ...prev,
        ...updatePos,
      }));
    },
    [onMouseMove]
  );

  const onInit = React.useCallback(() => {
    if (!visibility) {
      return;
    }

    document.querySelector("html").style.overflow = "hidden";

    const maxHeight = Math.round(window.innerHeight * 0.9);

    const modalEl = ReactDOM.findDOMNode(modalRef.current);
    const { width } = modalEl.getBoundingClientRect();
    const containerWidth = window.innerWidth;
    const containerHeight = window.innerHeight;

    setStatus((prev) => ({
      ...prev,
      maxHeight,
      left: containerWidth * 0.5 - width * 0.5,
      top: containerHeight * 0.5 - maxHeight * 0.5,
    }));

    //
    return () => {
      document.querySelector("html").style.overflow = "";
    };
  }, [visibility]);

  React.useEffect(() => {
    onInit();

    window.addEventListener("resize", onInit);

    return () => {
      window.removeEventListener("resize", onInit);
    };
  }, [onInit]);

  React.useEffect(() => {
    if (!visibility) return;

    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("mousemove", onMouseMove);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseDown);
    };
  }, [onMouseDown, onMouseMove, onMouseUp, visibility]);

  const modalStyles = React.useMemo(() => {
    return {
      top: `${status.top}px`,
      left: `${status.left}px`,
    };
  }, [status.left, status.top]);

  const renderModal = (
    <Styled.ModalWrapper
      modalWidth={status.minWidth}
      modalHeight={status.maxHeight}
      ref={modalRef}
      style={modalStyles}
      draggable={status.isDragging}
      className={["draggable__modal", className].join(" ")}
      modalCss={modalCss}
    >
      {/* Loading animation */}
      <AnimatePresence>
        {isLoading ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="loading"
          />
        ) : null}
      </AnimatePresence>

      <div
        className={`draggable__modal__header ${modalHeaderClassName}`}
        onMouseDown={onMouseDown}
      >
        <div className="close__btn" role="button" onClick={onClose}>
          <IconBlock>
            <IconClose />
          </IconBlock>
        </div>

        {header}
      </div>

      <div className="draggable__modal__content">{children}</div>
    </Styled.ModalWrapper>
  );

  return visibility
    ? ReactDOM.createPortal(
        renderModal,
        document.getElementById("modal-backdrop")
      )
    : null;
};

export default React.forwardRef(DraggableModal);
