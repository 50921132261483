export const ROUTE_OPTIONS = {
  dashboard: {
    id: "dashboard",
    label: "dashboard",
    path: "dashboard",
  },

  setting: {
    id: "old_tenant_setting",
    menuIcon: "menu-settings-01",
    label: "Setting",
    path: "/setting",
    children: {
      "system-rights": {
        path: "system-rights",
        id: "system-rights",
        label: "System Rights",
      },

      "system-generals": {
        id: "system-generals",
        path: "system-generals",
        label: "System Generals",
      },

      "data-setup": {
        id: "data-setup",
        path: "data-setup",
        label: "Data Setup",
      },

      "system-client": {
        id: "system-client",
        path: "system-client",
        label: "Client",
      },

      "project-setup": {
        id: "project-setup",
        path: "project-setup",
        label: "Project Setup",
      },

      "system-company": {
        id: "system-company",
        path: "system-company",
        label: "Company",
      },

      "my-profile": {
        id: "my-profile",
        path: "my-profile",
        label: "My Profile",
      },

      "tenant-permission": {
        id: "tenant-permission",
        path: "tenant-permission",
        label: "Permission",
      },

      "tenant-client": {
        id: "tenant-client",
        path: "tenant-client",
        label: "Client",
      },

      "tenant-company": {
        id: "tenant-company",
        path: "tenant-company",
        label: "Company",
      },

      "tenant-project": {
        id: "tenant-project",
        path: "tenant-project",
        label: "Project",
      },

      "template-general-settings": {
        id: "template-general-settings",
        path: "template-general-settings",
        label: "General Settings",
      },
    },
  },

  projects: {
    id: "projects",
    menuIcon: "main-menu-projects",
    label: "Projects",
    path: "/projects",
    children: {
      "new-project": {
        id: "new-project",
        path: "",
        label: "New Project",
      },

      onboarding: {
        id: "onboarding",
        path: "onboarding",
        label: "Onboarding",
        children: {
          // Onboarding sub links
          "all-members": {
            id: "all-members",
            path: "all-members",
            label: "All Members",
          },

          "shortlisted-list": {
            id: "shortlisted-list",
            path: "shortlisted-list",
            label: "Shortlisted List",
          },

          "onboarding-list": {
            id: "onboarding-list",
            path: "onboarding-list",
            label: "Onboarding List",
          },

          "hr-list": {
            id: "hr-list",
            path: "hr-list",
            label: "HR List",
          },

          favorites: {
            id: "favorites",
            path: "favorites",
            label: "Favorites",
          },
        },
      },

      "project-brief": {
        id: "project-brief",
        path: "brief/:id",
        label: "Project Brief",
      },

      // "project-dashboard": {
      //   id: "project-dashboard",
      //   path: "dashboard/:id",
      //   label: "Project Dashboard",
      // },

      "view-contract": {
        id: "view-contract",
        path: "view-contract/:userId/:contractId/:contractRequestId/:source",
        label: "View Contract",
      },
    },
  },

  crew: {
    id: "team",
    menuIcon: "menu-team-hierarchy-01",
    label: "Team",
    path: "/crew",
    children: {
      "crew-planner": {
        id: "crew-planner",
        path: "",
        label: "Team planner",
      },
    },
  },

  templates: {
    id: "template",
    menuIcon: "menu-templates-01",
    label: "Templates",
    path: "/templates",
    children: {
      "all-templates": {
        id: "all-templates",
        path: "",
        label: "All Templates",
      },

      "create-templates": {
        id: "create-templates",
        path: "create",
        label: "New Templates",
      },
    },
  },

  template_v2: {
    id: "template_v2",
    menuIcon: "menu-templates-02",
    label: "Template_v2",
    path: "/template-v2",
    children: {
      "create-template-v2": {
        id: "create-template-v2",
        path: "/template-v2/create",
        label: "Create Template",
      },

      "edit-template-v2": {
        id: "edit-templates-v2",
        path: "/template-v2/edit",
        label: "Edit Template",
      },
    },
  },

  research_board: {
    id: "research-board",
    menuIcon: "menu-research-tool-01",
    label: "Research",
    path: "/research-board",
    children: {
      // "research-organizer": {
      //   id: "research-organizer",
      //   path: "organizer",
      //   label: "Research Organizer",
      // },

      // "research-data": {
      //   id: "research-data",
      //   path: "research-data",
      //   label: "Research Data",
      // },

      // "beat-sheet": {
      //   id: "beat-sheet",
      //   path: "beat-sheet",
      //   label: "Beat Sheet",
      // },

      // "shot-list": {
      //   id: "shot-list",
      //   path: "shot-list",
      //   label: "Shot List",
      // },

      // "call-sheet": {
      //   id: "call-sheet",
      //   path: "call-sheet",
      //   label: "Call Sheet",
      // },

      documents: {
        id: "documents",
        path: "documents",
        label: "Documents",
      },
    },
  },

  // Temp
  "project-settings": {
    id: "project-settings",
    menuIcon: "menu-settings-02",
    path: "/setting-ui/project/:id",
    label: "Project Settings",
  },

  scheduler: {
    id: "scheduler",
    menuIcon: "menu-scheduler-tool-01",
    label: "Scheduler Tool",
  },

  budget: {
    id: "budget",
    menuIcon: "menu-budget-01",
    label: "Budgeting Tool",
  },
};
