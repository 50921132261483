import React from "react";
import * as Styled from "./styled";
import WeekRow from "./components/weekRow";
import HolidaysTable from "./components/HolidaysTable"

function FormThree() {
  return (
    <Styled.MainConatiner>
      <div className="weekDaySelectorContainer">
        <WeekRow />
      </div>

      <HolidaysTable />
    </Styled.MainConatiner>
  );
}

export default FormThree;
