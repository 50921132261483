import Schedule_API from "../../../services/api-scheduler";
import { SCHEDULE_ENDPOINTS } from "./schedule.endpoints";

export const getAllBlocks = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Schedule_API.get(
        `${SCHEDULE_ENDPOINTS.GET_All_BLOCK}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      //   const data = response;
      const mappedData = response?.data?.result.map((block) => {
        return {
          name: block?.blockName,
          activities: "",
          id: block?.blockTypeId || 0,
          data: block,
        };
      });

      resolve(mappedData);

      //
    } catch (error) {
      reject(error);
    }
  });
};

export const saveBlockWithActivities = async (data) => {
  const requestBody = {
    blockTypeId: data?.blockTypeId || 0,
    blockName: data?.name,
    columnTypeId: data?.stage,
    status: 1,
    bgColour: null,
    blockActivities: data?.activity.map((activity, index) => ({
      blockActivityTypeId: activity?.blockActivityTypeId || 0,
      activityItemTypeId: activity?.activityItemTypeId || 0,
      itemTypeId: activity?.itemTypeId || 0,
      itemName: activity?.name,
      shortName: activity?.code,
      icon: activity?.icon,
      iconColour: null,
      textColour: null,
      bgColour: activity?.color,
      childTypeId: 1,
      defaultDayCount: 1,
      order: index + 1,
    })),
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await Schedule_API.post(
        `${SCHEDULE_ENDPOINTS.SAVE_BLOCK}`,
        requestBody
      );
      const result = response || null;
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllStages = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Schedule_API.get(
        `${SCHEDULE_ENDPOINTS.GET_STATGES}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const data = response;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const deleteBlock = (blockTypeId) => {
  return new Promise((resolve, reject) => {
    Schedule_API.delete(`${SCHEDULE_ENDPOINTS.DELETE_BLOCK}/${blockTypeId}`)
      .then((res) => {
        console.log("deleteBlock :::", res);
        resolve(res.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
