import React from "react";
import { setMonth, startOfMonth } from "date-fns";
import { useDayPicker } from "react-day-picker";
// import * as Styled from "../styled";

const Months = (props) => {
  const { displayMonth } = props;
  const { today } = useDayPicker();
  const [currentDate, setCurrentDate] = React.useState(displayMonth || today);

  const monthsList = [];

  const date = startOfMonth(new Date());

  for (let month = 0; month <= 11; month++) {
    monthsList.push(setMonth(date, month));
  }

  React.useEffect(() => {
    setCurrentDate(displayMonth);
  }, [displayMonth]);

  const onChangeHandler = (e) => {
    const val = e.target.value;
    const newMonth = setMonth(startOfMonth(displayMonth), Number(val));
    props.onChange(newMonth);
    setCurrentDate(newMonth);
  };

  return (
    <select onChange={onChangeHandler} value={currentDate.getMonth()}>
      {monthsList.map((m, i) => {
        let label = m.toLocaleString("en-US", { month: "long" });

        return <option value={m.getMonth()}>{label}</option>;
      })}
    </select>
  );
};

export default Months;
