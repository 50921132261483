import React from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Table from "../Table";

export const columnHelper = createColumnHelper();
export const Elements = Table.Elements;

const sortClzTypes = {
  asc: "asc",
  desc: "desc",
};

function TanStackTable(props) {
  const {
    columns = [],
    /**
     * Table column items.
     * Example :- 
     * 
     * const columns = [
        columnHelper.accessor((row) => row.lastName, {
          id: "lastName",
          cell: (info) => <i>{info.getValue()}</i>,
          header: () => <span>First Name</span>,
        }),
        columnHelper.accessor("age", {
          header: () => "Age",
          cell: (info) => info.renderValue(),
        }),
      ];
     */

    data = [],
    /**
     *The table date must be match the provided columns
     */

    enabledRowsSpace = false,
    /**
     * Keep spacing between the each rows of the table.
     * default false
     */

    emptyRowCaption = "There is no data to show",
    emptyRowMessage = "Please add item or other attributes",
    isLoading,
    isRefetching,
    ...otherProps
  } = props;

  const [sorting, setSorting] = React.useState([]);
  const [expanded, setExpanded] = React.useState({});

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      expanded,
    },
    getSubRows: (row) => row.subRows,
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <Table isRowPacked={!enabledRowsSpace} {...otherProps}>
      <Table.Head>
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.TR key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              let label = header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  );

              const canSort = header.column.getCanSort();

              return (
                <Table.TH
                  {...{
                    key: header.id,
                    style: {
                      width: header.getSize(),
                    },
                    className: [
                      canSort ? "can__sortable" : "",
                      sortClzTypes[header.column.getIsSorted()],
                    ].join(" "),
                    colSpan: header.colSpan,
                    onClick: canSort
                      ? header.column.getToggleSortingHandler()
                      : null,
                  }}
                >
                  {label}

                  {/* Sortable button */}
                  {canSort && <Table.Elements.SortableIcon />}
                </Table.TH>
              );
            })}
          </Table.TR>
        ))}
      </Table.Head>

      {isLoading || isRefetching ? (
        <Table.Body>
          <Table.TR>
            <Table.TD colSpan="20" align="center">
              {isRefetching ? "Updating..." : "Loading..."}
            </Table.TD>
          </Table.TR>
        </Table.Body>
      ) : (
        <Table.Body>
          {table.getRowModel().rows.length > 0 ? (
            <>
              {table.getRowModel().rows.map((row) => (
                <Table.TR key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Table.TD key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Table.TD>
                    );
                  })}
                </Table.TR>
              ))}
            </>
          ) : (
            <>
              <Table.TR className="table__empty__row">
                <Table.TD colSpan="20">
                  <div className="empty__block">
                    <div className="empty__block__caption">
                      {emptyRowCaption}
                    </div>
                    {emptyRowMessage && (
                      <div className="empty__block__message">
                        {emptyRowMessage}
                      </div>
                    )}
                  </div>
                </Table.TD>
              </Table.TR>
            </>
          )}
        </Table.Body>
      )}
    </Table>
  );
}

export default TanStackTable;
