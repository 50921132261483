import React from "react";
import { TaskListWrapper, Wrapper } from "./styled";

const TaskItemGroup = ({ date, children, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <div className="task-item-group">
        <div className="task-item-group__start">{date}</div>
        <div className="task-item-group__content">{children}</div>
      </div>
    </Wrapper>
  );
};

export const TaskList = TaskListWrapper;

export default TaskItemGroup;
