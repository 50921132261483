// Main menu icons
import MenuProject01 from "../../icons/Menu/projects/01";
import MenuResearchTool01 from "../../icons/Menu/research-tool/01";
import MenuSchedulerTool01 from "../../icons/Menu/scheduler-tool/01";
import MenuSettings01 from "../../icons/Menu/settings/01";
import MenuTeamHierarchy01 from "../../icons/Menu/team-hierarchy/01";
import MenuTemplates01 from "../../icons/Menu/templates/01";
import MenuBudget01 from "../../icons/Menu/Budget/01";

import MenuSettings02 from "../../icons/Fill/settings";

const mainMenuIcons = [
  {
    type: "main-menu",
    name: "main-menu-projects",
    icon: MenuProject01,
  },
  {
    type: "main-menu",
    name: "menu-research-tool-01",
    icon: MenuResearchTool01,
  },
  {
    type: "main-menu",
    name: "menu-scheduler-tool-01",
    icon: MenuSchedulerTool01,
  },
  {
    type: "main-menu",
    name: "menu-settings-01",
    icon: MenuSettings01,
  },
  {
    type: "main-menu",
    name: "menu-team-hierarchy-01",
    icon: MenuTeamHierarchy01,
  },
  {
    type: "main-menu",
    name: "menu-templates-01",
    icon: MenuTemplates01,
  },
  {
    type: "main-menu",
    name: "menu-budget-01",
    icon: MenuBudget01,
  },
  {
    type: "main-menu",
    name: "menu-settings-02",
    icon: MenuSettings02,
  },
];

export default mainMenuIcons;
