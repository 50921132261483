import React from "react";
import { Wrapper } from "./styled";

const TaskItem = ({ count, actions, content, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <div className="task-item">
        <div className="task-item__start">
          {count ? <div className="task-item__count">{count}</div> : null}
        </div>

        <div className="task-item__center">{content}</div>

        {actions ? <div className="task-item__end">{actions}</div> : null}
      </div>
    </Wrapper>
  );
};

export default TaskItem;
