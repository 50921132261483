import React from "react";
import { v4 as uuidv4 } from "uuid";
import { produce } from "immer";

export const FileManagerContext = React.createContext({
  sections: [],
  value: [],
  disabled: false,
  allowTypes: null,
  unsortedFiles: [],
  onDelete: (id) => {},
  onEdit: (id) => {},
  onSave: (id) => {},
  onFileUploaded: (files = []) => {},
});

export const useFileManager = () => {
  return React.useContext(FileManagerContext);
};

export const FileManagerProvider = ({
  children,
  defaultSections = [],
  defaultValue = [],
  disabled,
  onChange,
}) => {
  const [value, setValue] = React.useState([]);
  const [uploadSections, setUploadSections] = React.useState([]);
  const [isDisabled, setIsDisabled] = React.useState(disabled);
  const [shouldUpdate, setShouldUpdate] = React.useState(false);

  React.useEffect(() => {
    setUploadSections(defaultSections);
    setValue(defaultValue);
    setIsDisabled(disabled);
  }, [defaultValue, disabled, defaultSections]);

  const unsortedFiles = React.useMemo(() => {
    if (value.length === 0) return [];

    return value.filter((file) => file.isSorted === false);
  }, [value]);

  const onFileUploadedHandler = React.useCallback((files = []) => {
    files = files.map((file, i) => {
      const ext = file.fileName?.split(".");

      return {
        ...file,
        localId: uuidv4(),
        originalName: file.fileName,
        extension: ext ? ext[1] : null,
        sectionValue: null,
        mode: "view",
        isSorted: false,
      };
    });

    setValue(
      produce((draft) => {
        return draft.concat(files);
      })
    );

    setShouldUpdate(true);
  }, []);

  const onEditHandler = React.useCallback((id) => {
    setValue(
      produce((draft) => {
        const file = draft.find((f) => f.localId === id);
        file.mode = "edit";
      })
    );
  }, []);

  const onDeleteHandler = React.useCallback(
    (id) => {
      const isExists = value.find((f) => f.localId === id);

      if (!isExists) {
        return;
      }

      setValue(
        produce((draft) => {
          return draft.filter((f) => f.localId !== id);
        })
      );

      setShouldUpdate(true);
    },
    [value]
  );

  const onSaveHandler = React.useCallback(({ formValues, localId }) => {
    if (!formValues.sectionValue || formValues.fileName.length === 0) {
      return;
    }

    setValue(
      produce((draft) => {
        const obj = draft.find((d) => d.localId === localId);
        obj.fileName = formValues.fileName;
        obj.sectionValue = formValues.sectionValue;
        obj.isSorted = true;
        obj.mode = "view";
      })
    );

    setShouldUpdate(true);
  }, []);

  React.useEffect(() => {
    if (shouldUpdate) {
      const sortedFiles = produce(value, (draft) => {
        return draft.filter((file) => file.isSorted);
      });

      // Get sorted files by section wise.
      const sortedFilesBySection = produce(uploadSections, (draft) => {
        draft.forEach((sec) => {
          sec.files = value.filter(
            (file) => file.sectionValue === sec.value && file.isSorted
          );
        });
      });

      if (onChange && typeof onChange === "function") {
        onChange({ value, unsortedFiles, sortedFiles, sortedFilesBySection });
      }

      setShouldUpdate(false);
    }
  }, [shouldUpdate, onChange, value, unsortedFiles, uploadSections]);

  const providerValues = React.useMemo(
    () => ({
      sections: uploadSections,
      value,
      unsortedFiles,
      disabled: isDisabled,
      onFileUploaded: onFileUploadedHandler,
      onDelete: onDeleteHandler,
      onSave: onSaveHandler,
      onEdit: onEditHandler,
    }),
    [
      isDisabled,
      onDeleteHandler,
      onSaveHandler,
      onFileUploadedHandler,
      unsortedFiles,
      onEditHandler,
      value,
      uploadSections,
    ]
  );

  return (
    <FileManagerContext.Provider value={providerValues}>
      {children}
    </FileManagerContext.Provider>
  );
};
