import DocumentEditor from "@txtextcontrol/tx-react-ds-document-editor";
import React from "react";
import {
  getContextMenuCommentItem,
  readDocument,
  addUniqueItem,
  CONTEXT_MENU_ITEM_TEXT,
  getContextMenuFormFieldValues,
} from "../../services/TXTextController";
import useUserData from "../../../../../../hooks/useUserData";
import { VIEW_CONTRACT_SOURCES } from "../../../../../../constant/Status";
import { ContainerWrapper } from "./styled";
import { useAppStore } from "../../../../../../store/AppStore/AppStore";
import { TextControllerConfigs } from "../../constant";
import Alert from "../../../../../../utility/alert";
import { useNavigate } from "react-router-dom";
import { getFormFieldValues } from "../../../../../../Actions/Templates";

const EDIT_MODE = {
  Edit: "Edit",
  ReadAndSelect: "ReadAndSelect",
  ReadOnly: "ReadOnly",
  UsePassword: "UsePassword",
};
const MASTER_PASSWORD = "loop_d947cb8c";

const TXTextControlDocumentView = React.forwardRef(
  (
    {
      source,
      documentData,
      handleOnCreated,
      editorAttributes,
      handleDocumentState,
      member,
    },
    ref
  ) => {
    const [editorState, setEditorState] = React.useState({
      isEditorLoaded: false,
    });
    const [containerBodyExists, setContainerBodyExists] = React.useState(false);
    const [wrapperHeight, setWrapperHeight] = React.useState(null);
    const [formFields, setFormFields] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const isImageSelected = React.useRef(false);
    const USER = useUserData();
    const isOpenSideMenu = useAppStore((state) => state.isOpenSideMenu);
    const navigate = useNavigate();

    const showLoader = useAppStore((state) => state.showLoader);
    const hideLoader = useAppStore((state) => state.hideLoader);

    const handleSaveDocAsDocX = React.useCallback(
      ({ documentName = "loop_doc" }) => {
        return new Promise((resolve, reject) => {
          window.TXTextControl.saveDocument(
            window.TXTextControl.streamType.WordprocessingML,
            (e) => {
              if (e.error) {
                reject(e.error);
                return;
              }

              const documentData = {
                fileName: documentName + ".docx",
                documentData: e.data,
              };

              resolve(documentData);
            }
          );
        });
      },
      []
    );

    React.useImperativeHandle(ref, () => ({
      handleSaveDocAsDocX: handleSaveDocAsDocX,
    }));

    const activeEditMode = (editMode) => {
      switch (editMode) {
        case EDIT_MODE.Edit:
          window.TXTextControl.setEditMode(window.TXTextControl.EditMode.Edit);
          break;
        case EDIT_MODE.ReadAndSelect:
          window.TXTextControl.setEditMode(
            window.TXTextControl.EditMode.ReadAndSelect
          );
          break;
        case EDIT_MODE.ReadOnly:
          window.TXTextControl.setEditMode(
            window.TXTextControl.EditMode.ReadOnly
          );
          break;
        case EDIT_MODE.UsePassword:
          window.TXTextControl.setEditMode(
            window.TXTextControl.EditMode.ReadAndSelect |
              window.TXTextControl.EditMode.UsePassword
          );
          break;
        default:
          window.TXTextControl.setEditMode(window.TXTextControl.EditMode.Edit);
          break;
      }
    };

    const handleAddComments = () => {
      const selection = window.TXTextControl.selection;
      selection.getStart((selStart) => {
        selection.getLength((selLength) => {
          selection.getText((currentText) => {
            const comments = window.TXTextControl.comments;
            if (isImageSelected.current) {
              comments.addAtPosition("Add Comment: ", selStart, selLength + 1);
            } else {
              comments.addAtPosition("Add Comment: ", selStart + 1, selLength);
            }
          });
        });
      });
    };

    const customizeRibbonBar = () => {
      const ribbonbar = document.getElementById("ribbonbar");
      if (ribbonbar) {
        const tabsToRemove = [
          "li_tabReports",
          "li_tabReferences",
          "li_tabPermissions",
          "li_tabFormFields",
        ];
        tabsToRemove.forEach((tabId) => {
          const tab = document.getElementById(tabId);
          if (tab) {
            tab.remove();
          }
        });
        if (
          source === VIEW_CONTRACT_SOURCES.onboarding ||
          source === VIEW_CONTRACT_SOURCES.outside
        ) {
          window.TXTextControl.ribbon.minimize();
          var divElement = document.querySelector(".txTabTextContainer");
          divElement.classList.remove("selected");

          const tabLinks = ribbonbar.querySelectorAll(".tabs a");
          tabLinks.forEach((tabLink) => {
            tabLink.style.pointerEvents = "none";
          });
        }
      }
    };

    const commentAccessControl = (comment) => {
      if (source === VIEW_CONTRACT_SOURCES.onboarding) {
        window.TXTextControl.getUserNames((userNames) => {
          if (comment.commentedText.userName !== userNames[0]) {
            window.TXTextControl.undo(() => {
              window.TXTextControl.sendCommand(
                window.TXTextControl.Command.CommentsViewerRefresh
              );
              Alert.confirm({
                allowOutsideClick: false,
                title: "Not Permitted!",
                text: "Editing comments authored by others is not permitted.",
                confirmButtonText: "OK",
                showCancelButton: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  console.log("yes");
                }
              });
            });
          }
        });
      }
    };

    const handleTextControlLoaded = () => {
      window.TXTextControl.setStatusBarColor("#245A6C");
      window.TXTextControl.showRibbonBar(false);
      window.TXTextControl.showStatusBar(false);
      window.TXTextControl.showVerticalRuler(false);
      window.TXTextControl.showHorizontalRuler(false);

      var selection = window.TXTextControl.selection;
      selection.setCulture(TextControllerConfigs.culture);
      window.TXTextControl.enableCommands();

      window.TXTextControl.addEventListener(
        "ribbonTabLoaded",
        handleRibbonTabLoaded
      );

      window.TXTextControl.addEventListener("commentCreated", () => {
        window.TXTextControl.showSideBar(
          window.TXTextControl.SideBarType.Comments
        );
      });

      window.TXTextControl.addEventListener("commentChanged", (comment) => {
        commentAccessControl(comment);
      });

      window.TXTextControl.addEventListener("commentDeleted", (comment) => {
        commentAccessControl(comment);
      });

      window.TXTextControl.addEventListener(
        "commentedTextEntered",
        (comment) => {
          const active = comment.commentedText.active;
          if (source === VIEW_CONTRACT_SOURCES.outside && !active) {
            window.TXTextControl.comments.elementAt(
              comment.commentedText.id,
              (comment) => {
                window.TXTextControl.comments.remove(comment);
              }
            );
          }
        }
      );
    };

    const handleDocumentLoaded = () => {
      handleDocumentState({ isDocumentLoaded: true });
      handleOnCreated({
        sourceHRAccess,
        sourceOnBoardingAccess,
        sourceOutsideAccess,
      });

      switch (source) {
        case VIEW_CONTRACT_SOURCES.outside:
          window.TXTextControl.comments.forEach((comment) => {
            comment.setActive(false);
          });
          break;
        default:
          break;
      }
      setTimeout(() => {
        window.TXTextControl.showSideBar(
          window.TXTextControl.SideBarType.Comments
        );
      }, 4000);

      window.TXTextControl.addEventListener("imageSelected", (image) => {
        isImageSelected.current = true;
      });

      window.TXTextControl.addEventListener("imageDeselected", (image) => {
        isImageSelected.current = false;
      });
      hideLoader();
    };

    const handleContextMenuOpening = (e) => {
      const contextMenuItems = [
        getContextMenuFormFieldValues(formFields),
        getContextMenuCommentItem(handleAddComments),
      ];
      contextMenuItems.forEach((item) => addUniqueItem(e.items, item, source));

      const selection = window.TXTextControl.selection;
      selection.getLength((selLength) => {
        const checkDuplicateComments = (callback) => {
          selection.getStart((selStart) => {
            let isDuplicate = false;
            window.TXTextControl.comments.getCount((count) => {
              if (count === 0) {
                callback(false);
              }
            });
            if (isImageSelected.current) {
              selLength += 1;
              selStart -= 1;
            }
            window.TXTextControl.comments.forEach(
              (comment, index, itemCount) => {
                comment.getStart((getStart) => {
                  comment.getLength((getLength) => {
                    if (
                      selStart >= getStart - 1 &&
                      selStart < getStart - 1 + getLength
                    ) {
                      isDuplicate = true;
                      callback(true);
                    }
                    if (itemCount === index + 1 && !isDuplicate) {
                      callback(false);
                    }
                  });
                });
              }
            );
          });
        };

        checkDuplicateComments((duplicateComment) => {
          if (
            (selLength > 0 || isImageSelected.current) &&
            (source === VIEW_CONTRACT_SOURCES.hr
              ? true
              : editorAttributes.current.isGenerateRequestMode) &&
            !duplicateComment
          ) {
            e.items.forEach((item) => {
              if (item.text === CONTEXT_MENU_ITEM_TEXT.COMMENT) {
                item.isEnabled = true;
              }
            });

            const txContextMenu = document.getElementById("txContextMenu");
            const liElements = txContextMenu.querySelectorAll("li");
            liElements.forEach((li) => {
              const pElement = li.querySelector("p");
              if (
                pElement &&
                pElement.textContent.trim() === CONTEXT_MENU_ITEM_TEXT.COMMENT
              ) {
                li.classList.remove("txui-state-disabled");
                li.removeAttribute("aria-disabled");
              }
            });
          }
        });
      });
    };

    const handleRibbonTabLoaded = (e) => {
      customizeRibbonBar();
    };

    const handleRibbonTabsLoaded = () => {
      setEditorState((prevState) => ({
        ...prevState,
        isEditorLoaded: true,
      }));
      window.TXTextControl.sideBarToggleButton.setAllowedSideBars(
        window.TXTextControl.SideBarType.FieldNavigator |
          window.TXTextControl.SideBarType.Comments
      );
      window.TXTextControl.clipboardMode =
        window.TXTextControl.ClipboardMode.Client;
      window.TXTextControl.setIsSpellCheckingEnabled(true);

      var ulTabs = document.querySelector("ul.tabs");
      ulTabs.addEventListener("click", (e) => {
        if (
          source === VIEW_CONTRACT_SOURCES.onboarding ||
          source === VIEW_CONTRACT_SOURCES.outside
        ) {
          window.TXTextControl.ribbon.minimize();
        } else {
          window.TXTextControl.ribbon.expand();
        }
      });

      ulTabs.addEventListener("dblclick", (e) => {
        if (
          source !== VIEW_CONTRACT_SOURCES.onboarding &&
          source !== VIEW_CONTRACT_SOURCES.outside
        ) {
          window.TXTextControl.ribbon.minimize();
        }
      });
    };

    const handleWebSocketClosed = (e) => {
      window.TXTextControl.removeFromDom();
      window.location.reload(true);
    };

    const EVENT_LISTENERS = [
      { event: "textControlLoaded", handler: handleTextControlLoaded },
      { event: "documentLoaded", handler: handleDocumentLoaded },
      { event: "contextMenuOpening", handler: handleContextMenuOpening },
      { event: "ribbonTabsLoaded", handler: handleRibbonTabsLoaded },
      { event: "webSocketClosed", handler: handleWebSocketClosed },
    ];

    const handleDocumentEditorLoad = () => {
      EVENT_LISTENERS.forEach(({ event, handler }) => {
        window.TXTextControl.addEventListener(event, handler);
      });
    };

    const sourceHRAccess = () => {
      console.log("HR access granted to Document Editor");
      activeEditMode(EDIT_MODE.Edit);
    };

    const sourceOnBoardingAccess = () => {
      console.log("Onboarding access granted to Document Editor");
      activeEditMode(EDIT_MODE.ReadAndSelect);
    };

    const sourceOutsideAccess = () => {
      console.log("Outside access granted to Document Editor");
      activeEditMode(EDIT_MODE.ReadAndSelect);
    };

    const handleCleanUpDOM = () => {
      EVENT_LISTENERS.forEach(({ event, handler }) => {
        window.TXTextControl.removeEventListener(event, handler);
      });
      window.TXTextControl.removeFromDom();
      editorAttributes.current.isGenerateRequestMode = false;
    };

    React.useEffect(() => {
      if (editorState.isEditorLoaded) {
        if (source === VIEW_CONTRACT_SOURCES.hr) {
          readDocument(documentData, MASTER_PASSWORD);
        } else {
          readDocument(documentData);
        }
      }
    }, [documentData, editorState.isEditorLoaded, source]);

    React.useEffect(() => {
      if (editorState.isEditorLoaded) {
        setTimeout(() => {
          window.TXTextControl.refreshLayout();
        }, 300);
      }
    }, [isOpenSideMenu, editorState.isEditorLoaded]);

    React.useEffect(() => {
      getFormFieldValues(member?.userTenantId).then((result) => {
        setFormFields(result);
        setIsLoading(false);
      });
    }, [member?.userTenantId]);

    React.useEffect(() => {
      const isRefreshed = localStorage.getItem("isRefreshed");
      if (!isRefreshed) {
        localStorage.setItem("isRefreshed", "true");
        setTimeout(() => {
          navigate(0);
        }, 100);
      } else {
        localStorage.removeItem("isRefreshed");
        showLoader();
        const containerBody = document.getElementById("containerBody");
        if (containerBody) {
          setContainerBodyExists(true);
          setWrapperHeight(containerBody.clientHeight + "px");
        }
        editorAttributes.current.isGenerateRequestMode = false;
      }

      return () => {
        handleCleanUpDOM();
      };
    }, []);

    return (
      <>
        {containerBodyExists && !isLoading && (
          <ContainerWrapper containerHeight={wrapperHeight} source={source}>
            <DocumentEditor
              style={{ width: "100%", height: "100%" }}
              serviceURL={process.env.REACT_APP_TXTEXTCONTROLLER_SERVICE_URL}
              onLoad={handleDocumentEditorLoad}
              authSettings={{
                clientId: process.env.REACT_APP_TXTEXTCONTROLLER_CLIENT_ID,
                clientSecret:
                  process.env.REACT_APP_TXTEXTCONTROLLER_CLIENT_SECRET,
              }}
              reconnectTimeout={20}
              userNames={[USER.userInfo.email]}
              uiCulture={TextControllerConfigs.uiCulture}
            />
          </ContainerWrapper>
        )}
      </>
    );
  }
);

export default TXTextControlDocumentView;
