import styled from "styled-components";

export const PopoverContent = styled.div`
  z-index: var(--zindex-popover);

  .popper__arrow,
  .popper__arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: inherit;
  }

  .popper__arrow {
    visibility: hidden;
  }

  .popper__arrow::before {
    visibility: visible;
    content: "";
    transform: rotate(45deg);
  }

  &[data-popper-placement^="top"] > .popper__arrow {
    bottom: -4px;
  }

  &[data-popper-placement^="bottom"] > .popper__arrow {
    top: -4px;
  }

  &[data-popper-placement^="left"] > .popper__arrow {
    right: -4px;
  }

  &[data-popper-placement^="right"] > .popper__arrow {
    left: -4px;
  }
`;

export const PopoverOverLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: var(--zindex-popover-overlayer);
`;
