import React from "react";
import * as Styled from "../styled";
import { IconBlock } from "../../../../../styled/Common/Icons";
import IconBin from "../../../icons/Line/Delete/01";
import IconLink from "../../../icons/Line/Link/02";
// import IconEdit from "../../../icons/Line/Edit/01";
import Typography from "../../../Typography";

export const ListItem = ({ id, name, url, onDelete, onEdit }) => {
  return (
    <Styled.ListItem>
      <div className="start">
        <IconBlock className="icon">
          <IconLink />
        </IconBlock>

        <Typography
          href={url}
          target="blank"
          variant={Typography.font_variant.small}
          as="a"
          className="link__text"
        >
          {name}
        </Typography>
      </div>

      {/* 
        Actions
      */}
      <div className="end">
        {/* <Styled.IconButton title="Edit" onClick={() => onEdit(id)}>
          <IconBlock className="icon">
            <IconEdit />
          </IconBlock>
        </Styled.IconButton> */}

        <Styled.IconButton title="Delete" onClick={() => onDelete(id)}>
          <IconBlock className="icon">
            <IconBin />
          </IconBlock>
        </Styled.IconButton>
      </div>
    </Styled.ListItem>
  );
};
