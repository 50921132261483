import React from "react";
import * as Styled from "./styled";
import { useScheduleContext } from "../../../../context/ScheduleContext";

const YearColumn = () => {
  const { years, currentFocusDate } = useScheduleContext();

  return (
    <Styled.YearColumn className="year__column" label="Yr" isFixed>
      <Styled.YearItems>
        {years?.map((year, i) => (
          <YearItem
            year={year}
            key={i}
            isCurrent={new Date(currentFocusDate).getFullYear() === year}
          />
        ))}
      </Styled.YearItems>
    </Styled.YearColumn>
  );
};

function YearItem({ year, isCurrent }) {
  return <Styled.YearItem $isCurrent={isCurrent}>{year}</Styled.YearItem>;
}

export default YearColumn;
